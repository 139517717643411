import { createContext } from 'react';
import { UserLocalStorage } from '../models/user-local-storage';

interface AuthContext {
  user: UserLocalStorage | null;
  setUser: (user: UserLocalStorage | null) => void;
}

export const AuthContext = createContext<AuthContext>({
  user: null,
  setUser: () => {}
});
