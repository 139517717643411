import {AxiosResponse, HttpStatusCode} from "axios";
import axiosPrivate from "../models/axios/axios-private";
import {
  GET_REPORTS_MEMBER_STATUS_BY_MONTH, GET_REPORTS_MEMBERS,
  GET_REPORTS_OWED_MEMBERSHIP_FEES
} from "../constants";

export const makeGetOwedMembershipFeesReportCall = async(sportId?: string): Promise<AxiosResponse | null> => {
  let endpoint = GET_REPORTS_OWED_MEMBERSHIP_FEES;
  if (sportId) {
    endpoint += `?sportId=${sportId}`;
  }
  const response = await axiosPrivate.get(endpoint);
  if (response.status === HttpStatusCode.Ok) {
    return response;
  } else {
    console.log(
      `Error while trying to get report: ${response.statusText}`
    );
    return null;
  }
};

export const makeGetMemberStatusByMonthReportCall = async(month?: number, year?: number, sportId?: string): Promise<AxiosResponse | null> => {
  if (!month || !year) {
    month = new Date().getMonth() + 1;
    year = new Date().getFullYear();
  }
  let endpoint = GET_REPORTS_MEMBER_STATUS_BY_MONTH.replace('{Month}', month.toString()).replace('{Year}', year.toString());
  if (sportId) {
    endpoint = endpoint.concat('&sportId={SportId}'.replace('{SportId}', sportId));
  }
  const response = await axiosPrivate.get(endpoint);
  if (response.status === HttpStatusCode.Ok) {
    return response;
  } else {
    console.log(
      `Error while trying to get report: ${response.statusText}`
    );
    return null;
  }
};

export const makeGetMembersReportCall = async(memberTypeId?: string, sportId?: string): Promise<AxiosResponse | null> => {
  let endpoint = GET_REPORTS_MEMBERS;
  if (memberTypeId || sportId) {
    endpoint += '?';
  }
  if (memberTypeId) {
    endpoint = endpoint.concat('memberTypeId={MemberTypeId}'.replace('{MemberTypeId}', memberTypeId));
  }
  if (sportId) {
    if (memberTypeId) {
      endpoint += '&';
    }
    endpoint = endpoint.concat('sportId={SportId}'.replace('{SportId}', sportId));
  }
  const response = await axiosPrivate.get(endpoint);
  if (response.status === HttpStatusCode.Ok) {
    return response;
  } else {
    console.log(
      `Error while trying to get report: ${response.statusText}`
    );
    return null;
  }
}
