import {Form, InputGroup} from "react-bootstrap";
import React, {ReactElement} from "react";
import { KeyValuePair } from "../../models/key-value-pair";

export interface Props {
  controlId: string;
  label: string;
  type: string;
  name: string;
  value?: string | number | string[] | undefined;
  onChangeEvent: React.ChangeEventHandler | undefined;
  onBlurEvent?: React.FocusEventHandler | undefined;
  touchedField?: any;
  errorField?: any;
  isDisabled?: boolean;
  placeholder?: string;
  isHidden?: boolean;
  selectOptions?: KeyValuePair[];
  isSelectWithValueAlreadySelected?: boolean;
  useInputGroup?: boolean;
  addonButton?: ReactElement;
}

export default function FormInput(props: Props) {
  const getFormControlInput = () => {
    return (
      <Form.Control
        type={props.type}
        name={props.name}
        value={props.value}
        onChange={props.onChangeEvent}
        onBlur={props.onBlurEvent}
        isInvalid={props.touchedField && !!props.errorField}
        isValid={props.touchedField && !props.errorField}
        placeholder={props.placeholder}
        disabled={props.isDisabled}
      />)
  }

  const getFormControlTextarea = () => {
    return (
      <Form.Control
        type={props.type}
        name={props.name}
        value={props.value}
        onChange={props.onChangeEvent}
        onBlur={props.onBlurEvent}
        isInvalid={props.touchedField && !!props.errorField}
        isValid={props.touchedField && !props.errorField}
        placeholder={props.placeholder}
        disabled={props.isDisabled}
        as="textarea"
        rows={3}
      />)
  }

  const getFormSelectInputOptions = () => {
    return props.selectOptions?.map((option) => {
      return (<option key={option.key ?? ''} value={option.key} disabled={option.disabled}>{option.value}</option>)
    });
  }

  const getFormSelectInput = () => {
    return (
      <Form.Select
        name={props.name}
        value={props.value}
        onChange={props.onChangeEvent}
        onBlur={props.onBlurEvent}
        isInvalid={props.touchedField && !props.isSelectWithValueAlreadySelected && !!props.errorField}
        isValid={props.touchedField && !props.errorField}
        aria-placeholder={props.placeholder}
        disabled={props.isDisabled}>
        {getFormSelectInputOptions()}
      </Form.Select>)
  }

  const getFormControlCheckbox = () => {
    return (
      <Form.Check
        type="checkbox"
        id={`default-checkbox`}
        label={props.label}
        onChange={props.onChangeEvent}
        onBlur={props.onBlurEvent}
      />
    )
  }

  const getFormControl = (type: string) => {
    switch (type) {
      case "select": return getFormSelectInput();
      case "textarea": return getFormControlTextarea();
      case "checkbox": return getFormControlCheckbox();
      default: return getFormControlInput();
    }
  }

  let inputGroup: ReactElement | null = null;
  if (props.addonButton) {
    inputGroup = (
      <InputGroup>
        { getFormControlInput() }
        { props.addonButton }
        <Form.Control.Feedback type="invalid">
          {props.errorField}
        </Form.Control.Feedback>
      </InputGroup>
    )
  }

  return (
    <Form.Group className="mb-3" controlId={props.controlId} hidden={props.isHidden}>
      <Form.Label>{ props.type !== 'checkbox' ? props.label : '' }</Form.Label>
      { inputGroup ?? getFormControl(props.type) }
      {
        inputGroup ? <></> : (
          <Form.Control.Feedback type="invalid">
            {props.errorField}
          </Form.Control.Feedback>
        )
      }
    </Form.Group>
  )
}
