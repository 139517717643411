import {Badge, Button, Col, Row, Stack} from "react-bootstrap";
import {makeGetMemberByIdCall} from "../../services/member-service";
import {Link, useLoaderData} from "react-router-dom";
import {Member} from "../../models/member/member";
import React, {useEffect, useState} from "react";
import {makeGetActivitiesFeesByMemberIdCall, makeGetMembershipFeesByMemberIdCall} from "../../services/product-service";
import {MembershipFeesByMemberResponse} from "../../models/web/membership-fees-by-member-response";
import {ActivitiesFeesByMemberResponse} from "../../models/web/activities-fees-by-member-response";
import {ACTIVITY_FEE_DISCRIMINATOR, MEMBERSHIP_FEE_DISCRIMINATOR} from "../../constants";

// @ts-expect-error this is required
export const viewMemberLoader = async({ params }): Promise<void> => {
  return (await makeGetMemberByIdCall(params.memberId))?.data;
};

export default function MemberStatus() {
  const member = useLoaderData() as Member;
  const [membershipFeesData, setMembershipFeesData] = useState<MembershipFeesByMemberResponse>();
  const [activitiesFeesData, setActivitiesFeesData] = useState<ActivitiesFeesByMemberResponse>();

  useEffect(() => {
    const getAdditionalMemberData = async () => {
      const membershipFees = await makeGetMembershipFeesByMemberIdCall(member.id!);
      const activitiesFees = await makeGetActivitiesFeesByMemberIdCall(member.id!);
      setMembershipFeesData(membershipFees?.data);
      setActivitiesFeesData(activitiesFees?.data);
    };

    getAdditionalMemberData().then(() => {});
  }, []);

  const getPersonalData = () => {
    return (
      <>
        <Stack direction="horizontal"><p className="font-weight-bold">Nombres:</p><p className="ml-2">{member.firstName}</p></Stack>
        <Stack direction="horizontal"><p className="font-weight-bold">Apellidos:</p><p className="ml-2">{member.lastName}</p></Stack>
      </>
    )
  }

  const getPaymentsSituation = (discriminator: string) => {
    let badgeText = 'Al día';
    let badgeType = 'success';
    if ((discriminator === MEMBERSHIP_FEE_DISCRIMINATOR && (membershipFeesData?.pendingMembershipFees ?? 0) > 0)
      || (discriminator === ACTIVITY_FEE_DISCRIMINATOR && (activitiesFeesData?.pendingActivitiesFees ?? 0) > 0))
    {
      badgeText = 'Deuda';
      badgeType = 'danger';
    }
    return <Badge bg={badgeType} className="ml-2 align-self-start">{badgeText}</Badge>
  }

  const getMemberData = () => {
    return (
      <>
        <Stack direction="horizontal"><p className="font-weight-bold">N° socio:</p><p className="ml-2">{member.memberNumber}</p></Stack>
        <Stack direction="horizontal"><p className="font-weight-bold">Tipo socio:</p><p className="ml-2">{member.memberType.value}</p></Stack>
        <Stack direction="horizontal"><p className="font-weight-bold">Situación de cuotas sociales:</p>{getPaymentsSituation(MEMBERSHIP_FEE_DISCRIMINATOR)}</Stack>
        <Stack direction="horizontal"><p className="font-weight-bold">Cuotas sociales adeudadas:</p><p className="ml-2">{membershipFeesData?.pendingMembershipFees}</p></Stack>
      </>
    )
  }

  const getActivitiesData = () => {
    return member.activities?.map((activity) => {
      if (!activity.isInsurance)
        return (
          <>
            <Stack direction="horizontal"><p className="font-weight-bold">Deporte:</p><p className="ml-2">{activity.sport?.name}</p></Stack>
            <Stack direction="horizontal"><p className="font-weight-bold">Actividad:</p><p className="ml-2">{activity.name}</p></Stack>
            <Stack direction="horizontal"><p className="font-weight-bold">Situación de actividad:</p>{getPaymentsSituation(ACTIVITY_FEE_DISCRIMINATOR)}</Stack>
            <Stack direction="horizontal"><p className="font-weight-bold">Aranceles adeudados:</p><p className="ml-2">{activitiesFeesData?.pendingActivitiesFees}</p></Stack>
          </>
        )
    });
  }

  return (
    <Row>
      <Col md={{ span: 9, offset: 2 }}>
        <h1>Situación del socio</h1>
        <p>Ficha del socio N°{member.memberNumber}.</p>
        <Row>
          <h2>Datos personales</h2>
          {getPersonalData()}
        </Row>
        <Row>
          <h2>Datos de socio</h2>
          {getMemberData()}
        </Row>
        <Row>
          <h2>Datos de actividades</h2>
          {getActivitiesData()}
        </Row>
        <Row>
          <Button type="button" variant="primary" className="w-25 align-self-start">
            <Link to={`../view/${member.id}`} className="link-button-primary">Ver ficha completa</Link>
          </Button>
        </Row>
      </Col>
    </Row>
  )
}
