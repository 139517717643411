import {AxiosError, AxiosResponse} from "axios";
import axiosPrivate from "../models/axios/axios-private";
import {GET_STATS} from "../constants";
import {getEndpointResponse} from "../models/endpoint-response";
import {HttpRequestResponse} from "../models/axios/http-request-response";
import {StatsOutcomeModel} from "../models/web/stats-outcome-model";

export const makeGetStatsCall = async(): Promise<HttpRequestResponse<StatsOutcomeModel[]>> => {
  let response: AxiosResponse | AxiosError;
  try {
    response = await axiosPrivate.get(GET_STATS);
  } catch (err: unknown) {
    response = err as AxiosError;
  }
  return getEndpointResponse(response);
};
