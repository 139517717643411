import {Button, Col, Form, Row} from "react-bootstrap";
import {useAuth} from "../hooks/use-auth";
import FormInput from "../components/forms/form-input";
import {useFormik} from "formik";
import {
  BUTTON_CONFIRM_UPDATE_LABEL, TOAST_FAILED_OPERATION_BODY,
  TOAST_FAILED_OPERATION_TITLE,
  TOAST_SUCCESSFUL_OPERATION_TITLE
} from "../constants";
import {makePatchUserCall} from "../services/user-service";
import {HttpStatusCode} from "axios";
import {useToastConfiguration} from "../App";
import {passwordUpdateSchema} from "../models/validations/password-update-schema";

/*
interface Props {
  toastConfiguration?: ToastConfiguration | null;
  afterSubmitting?: () => void;
}
*/

export default function UserConfig(/*props: Props*/) {
  const { user } = useAuth();
  const { toastConfiguration } = useToastConfiguration();
  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      newPasswordConfirmation: ''
    },
    validationSchema: passwordUpdateSchema,
    onSubmit: async () => {
      toastConfiguration?.setShow(true);
      toastConfiguration?.setOperationSuccessful(false);
      toastConfiguration?.setTitle(TOAST_FAILED_OPERATION_TITLE);
      toastConfiguration?.setBody(TOAST_FAILED_OPERATION_BODY);
      const response = await makePatchUserCall({
        id: user?.id as string,
        password: formik.values.currentPassword,
        newPassword: formik.values.newPassword
      });

      switch (response.statusCode) {
        case HttpStatusCode.Ok: {
          toastConfiguration?.setOperationSuccessful(true);
          toastConfiguration?.setTitle(TOAST_SUCCESSFUL_OPERATION_TITLE);
          toastConfiguration?.setBody('La contraseña ha sido actualizada exitosamente.');
          formik.resetForm();
          break;
        }
        case HttpStatusCode.Unauthorized: {
          toastConfiguration?.setBody('La contraseña actual no es correcta.');
          break;
        }
      }
    }
  });

  return (
    <>
      <h2>Configuración de usuario</h2>

      <p>Usuario: <strong>{user?.username}</strong></p>
      <h3>Cambiar contraseña</h3>
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col md={3}>
            <FormInput
              controlId="currentPassword"
              label="Contraseña actual"
              type="password"
              name="currentPassword"
              value={formik.values.currentPassword}
              onChangeEvent={formik.handleChange}
              onBlurEvent={formik.handleBlur}
              touchedField={formik.touched.currentPassword}
              errorField={formik.errors.currentPassword}
              isDisabled={false}
            />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <FormInput
              controlId="newPassword"
              label="Nueva contraseña"
              type="password"
              name="newPassword"
              value={formik.values.newPassword}
              onChangeEvent={formik.handleChange}
              onBlurEvent={formik.handleBlur}
              touchedField={formik.touched.newPassword}
              errorField={formik.errors.newPassword}
              isDisabled={false}
            />
            <FormInput
              controlId="newPasswordConfirmation"
              label="Repita nueva contraseña"
              type="password"
              name="newPasswordConfirmation"
              value={formik.values.newPasswordConfirmation}
              onChangeEvent={formik.handleChange}
              onBlurEvent={formik.handleBlur}
              touchedField={formik.touched.newPasswordConfirmation}
              errorField={formik.errors.newPasswordConfirmation}
              isDisabled={false}
            />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <div className="d-flex justify-content-center">
              <Button variant="primary" type="submit">{BUTTON_CONFIRM_UPDATE_LABEL}</Button>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  )
}
