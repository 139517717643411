import * as Yup from 'yup';

export const passwordUpdateSchema = Yup.object({
  currentPassword: Yup
    .string()
    .required('Ingrese su contraseña actual.'),
  newPassword: Yup
    .string()
    .required('Ingrese una nueva contraseña.')
    .min(5, 'La contraseña debe tener al menos 5 caracteres.')
    .max(255, 'La contraseña no puede tener más de 255 caracteres.'),
  newPasswordConfirmation: Yup.string()
    .required('Ingrese una nueva contraseña.')
    .min(5, 'La contraseña debe tener al menos 5 caracteres.')
    .max(255, 'La contraseña no puede tener más de 255 caracteres.')
    .test(
      'password-confirmation',
      'Las contraseña no coincide con la que ingresó anteriormente.',
      (value, context) => value === context.parent.newPassword
    )
})
