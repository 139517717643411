import {useAuth} from "../hooks/use-auth";
import {Navigate, useLocation} from "react-router-dom";
import React from "react";
import {LOGIN_PAGE_ROUTE} from "../constants";
import InactivityLogoutTimer from "./inactivity-logout-timer";

export interface Props {
  children: JSX.Element
}

export default function ProtectedRoute(props: Props): JSX.Element {
  const { isLoggedIn } = useAuth();
  const location = useLocation();
  if (location.pathname === LOGIN_PAGE_ROUTE ) {
    return props.children;
  }

  if (!isLoggedIn()) {
    return <Navigate to={LOGIN_PAGE_ROUTE} state={{ from: location }} replace />;
  }
  else {
    return <InactivityLogoutTimer children={props.children}></InactivityLogoutTimer>;
  }
}
