import {Col, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import ExportToExcel from "../../components/export-to-excel";
import {
  makeGetMembersReportCall,
  makeGetMemberStatusByMonthReportCall,
  makeGetOwedMembershipFeesReportCall
} from "../../services/report-service";
import FormInput from "../../components/forms/form-input";
import {getCurrentPeriod, getDropdownValues, getDropdownValuesV2, getPeriods, periodKeyToDate} from "../../helpers";
import {KeyValuePair} from "../../models/key-value-pair";
import {makeGetSportsCall} from "../../services/sport-service";
import {makeGetValuesListsCall} from "../../services/values-lists-service";
import {useAuth} from "../../hooks/use-auth";
import {Sport} from "../../models/sport";
import {
  GENERATE_REPORTS,
  GENERATE_REPORTS_MEMBER_STATUS_BY_MONTH_BASKET,
  GENERATE_REPORTS_MEMBER_STATUS_BY_MONTH_FOOTBALL,
  GENERATE_REPORTS_MEMBER_STATUS_BY_MONTH_HOCKEY,
  GENERATE_REPORTS_MEMBER_STATUS_BY_MONTH_TENNIS,
  GENERATE_REPORTS_MEMBERS_BASKET,
  GENERATE_REPORTS_MEMBERS_FOOTBALL,
  GENERATE_REPORTS_MEMBERS_HOCKEY,
  GENERATE_REPORTS_MEMBERS_TENNIS,
  GENERATE_REPORTS_OWED_MEMBERSHIP_FEES_BASKET,
  GENERATE_REPORTS_OWED_MEMBERSHIP_FEES_FOOTBALL,
  GENERATE_REPORTS_OWED_MEMBERSHIP_FEES_HOCKEY,
  GENERATE_REPORTS_OWED_MEMBERSHIP_FEES_TENNIS, SPORT_BASKET, SPORT_FOOTBALL, SPORT_HOCKEY, SPORT_TENNIS
} from "../../constants";

export default function Reports() {
  const { user } = useAuth();
  const [sports, setSports] = useState<Sport[]>([]);
  const [reports, setReports] = useState<KeyValuePair[]>([]);
  const [selectedReport, setSelectedReport] = useState<string>();
  const [selectedSport, setSelectedSport] = useState<string>();
  const [selectedPeriod, setSelectedPeriod] = useState<string>(getCurrentPeriod());
  const [selectedMemberType, setSelectedMemberType] = useState<string>();
  const [sportsKeyValueList, setSportsKeyValueList] = useState<KeyValuePair[]>();
  const [memberTypesKeyValueList, setMemberTypesKeyValueList] = useState<KeyValuePair[]>();
  const MemberStatusByMonthReportName = 'MemberStatusByMonth';
  const OwedMembershipFeesReportName = 'OwedMembershipFees';
  const MembersReportName = 'Members';

  useEffect(() => {
    const getSports = async () => {
      setSports((await makeGetSportsCall())?.data ?? []);
    }
    getSports().then();
  }, []);

  useEffect(() => {
    if (selectedReport === MembersReportName) {
      const func = async () => await makeGetValuesListsCall('719946dd-5573-4ff8-a7dd-a5b2ad4435c4');
      getDropdownValuesV2(func, setMemberTypesKeyValueList, false, true).then();
    }
  }, [selectedReport]);

  useEffect(() => {
    if (!user) return;

    const allReports: KeyValuePair[] = [
      {
        key: MemberStatusByMonthReportName,
        value: 'Rendición'
      },
      {
        key: OwedMembershipFeesReportName,
        value: 'Cuotas adeudadas'
      },
      {
        key: MembersReportName,
        value: 'Listado de socios'
      }
    ];
    let availableReports: KeyValuePair[] = [];
    const selectReportLabel: KeyValuePair = {
      key: '',
      value: 'Seleccione un reporte'
    };

    if (user.role.tasks.findIndex(x => x.name === GENERATE_REPORTS) >= 0) {
      setReports([selectReportLabel].concat(allReports));
      getDropdownValues(sports, setSportsKeyValueList, false, true).then();
      return;
    }

    const memberStatusByMonthReportNameTasks = [ GENERATE_REPORTS_MEMBER_STATUS_BY_MONTH_TENNIS, GENERATE_REPORTS_MEMBER_STATUS_BY_MONTH_BASKET, GENERATE_REPORTS_MEMBER_STATUS_BY_MONTH_FOOTBALL, GENERATE_REPORTS_MEMBER_STATUS_BY_MONTH_HOCKEY ];
    const owedMembershipFeesReportNameTasks = [ GENERATE_REPORTS_OWED_MEMBERSHIP_FEES_TENNIS, GENERATE_REPORTS_OWED_MEMBERSHIP_FEES_BASKET, GENERATE_REPORTS_OWED_MEMBERSHIP_FEES_FOOTBALL, GENERATE_REPORTS_OWED_MEMBERSHIP_FEES_HOCKEY ];
    const membersReportNameTasks = [ GENERATE_REPORTS_MEMBERS_TENNIS, GENERATE_REPORTS_MEMBERS_BASKET, GENERATE_REPORTS_MEMBERS_FOOTBALL, GENERATE_REPORTS_MEMBERS_HOCKEY ];

    for (const report of allReports) {
      let tasks: string[] = [];
      switch (report.key) {
        case MemberStatusByMonthReportName:
          tasks = memberStatusByMonthReportNameTasks;
          break;
        case OwedMembershipFeesReportName:
          tasks = owedMembershipFeesReportNameTasks;
          break;
        case MembersReportName:
          tasks = membersReportNameTasks;
          break;
      }

      for (const task of tasks) {
        if (user.role.tasks.findIndex(x => x.name === task) >= 0) {
          availableReports.push(report);
          break;
        }
      }
    }

    if (availableReports.length > 0) {
      const moreThanOne = availableReports.length > 1;
      if (moreThanOne) {
        availableReports = [selectReportLabel].concat(availableReports);
      }
      setSelectedReport(availableReports[0].key);
    }

    setReports(availableReports);

    const basketTasks = [ GENERATE_REPORTS_MEMBERS_BASKET, GENERATE_REPORTS_MEMBER_STATUS_BY_MONTH_BASKET, GENERATE_REPORTS_OWED_MEMBERSHIP_FEES_BASKET ];
    const footballTasks = [ GENERATE_REPORTS_MEMBERS_FOOTBALL, GENERATE_REPORTS_MEMBER_STATUS_BY_MONTH_FOOTBALL, GENERATE_REPORTS_OWED_MEMBERSHIP_FEES_FOOTBALL ];
    const hockeyTasks = [ GENERATE_REPORTS_MEMBERS_HOCKEY, GENERATE_REPORTS_MEMBER_STATUS_BY_MONTH_HOCKEY, GENERATE_REPORTS_OWED_MEMBERSHIP_FEES_HOCKEY ];
    const tennisTasks = [ GENERATE_REPORTS_MEMBERS_TENNIS, GENERATE_REPORTS_MEMBER_STATUS_BY_MONTH_TENNIS, GENERATE_REPORTS_OWED_MEMBERSHIP_FEES_TENNIS ];

    const availableSports: Sport[] = [];
    for (const sport of sports) {
      let tasks: string[] = [];
      switch (sport.id) {
        case SPORT_TENNIS:
          tasks = tennisTasks;
          break;
        case SPORT_BASKET:
          tasks = basketTasks;
          break;
        case SPORT_HOCKEY:
          tasks = hockeyTasks;
          break;
        case SPORT_FOOTBALL:
          tasks = footballTasks;
          break;
      }
      for (const task of tasks) {
        if (user.role.tasks.findIndex(x => x.name === task) >= 0) {
          availableSports.push(sport);
          break;
        }
      }
    }

    if (availableSports.length > 0) {
      const moreThanOne = availableSports.length > 1;
      getDropdownValues(availableSports, setSportsKeyValueList, moreThanOne, false).then();
      if (!moreThanOne) {
        setSelectedSport(availableSports[0].id);
      }
    }

  }, [sports, user?.role.tasks]);

  const getSportsDropdown = () => (
    <FormInput
      controlId="sport"
      label="Deporte"
      type="select"
      name="sport"
      value={selectedSport}
      onChangeEvent={(e: React.ChangeEvent<HTMLInputElement>) => { setSelectedSport(e.target.value) }}
      onBlurEvent={() => {}}
      touchedField={null}
      errorField={null}
      isDisabled={false}
      selectOptions={sportsKeyValueList}
    />
  )

  const getMemberStatusByMonthReport = () => {
    const dataSourceFunction = async () => {
      const yearMonth = periodKeyToDate(selectedPeriod);
      return await makeGetMemberStatusByMonthReportCall(yearMonth.month, yearMonth.year, selectedSport);
    }

    return (
      <Col xs={2}>
        <FormInput
          controlId="period"
          label="Período"
          type="select"
          name="period"
          value={selectedPeriod}
          onChangeEvent={(e: React.ChangeEvent<HTMLInputElement>) => { setSelectedPeriod(e.target.value) }}
          onBlurEvent={() => {}}
          touchedField={null}
          errorField={null}
          isDisabled={false}
          selectOptions={getPeriods()}
        />
        {getSportsDropdown()}
        <ExportToExcel dataSourceFunction={dataSourceFunction} buttonLabel="Obtener reporte" fileName="Rendición"/>
      </Col>
    )
  }

  const getOwedMembershipFeesReport = () => {
    const dataSourceFunction = async () => {
      return await makeGetOwedMembershipFeesReportCall(selectedSport);
    }

    return (
      <Col xs={2}>
        {getSportsDropdown()}
        <ExportToExcel dataSourceFunction={dataSourceFunction} buttonLabel="Obtener reporte" fileName="CuotasAdeudadas"/>
      </Col>
    )
  }

  const getMembersReport = () => {
    const dataSourceFunction = async () => {
      return await makeGetMembersReportCall(selectedMemberType, selectedSport);
    }

    return (
      <Col xs={2}>
        <FormInput
          controlId="memberType"
          label="Tipo de socio"
          type="select"
          name="memberType"
          value={selectedMemberType}
          onChangeEvent={(e: React.ChangeEvent<HTMLInputElement>) => { setSelectedMemberType(e.target.value) }}
          onBlurEvent={() => {}}
          touchedField={null}
          errorField={null}
          isDisabled={false}
          selectOptions={memberTypesKeyValueList}
        />
        {getSportsDropdown()}
        <ExportToExcel dataSourceFunction={dataSourceFunction} buttonLabel="Obtener reporte" fileName="Socios"/>
      </Col>
    )
  }

  const getReportSelector = () => {
    return (
      <Row>
        <Col xs={4}>
          <FormInput
            controlId="report"
            label="Reportes disponibles"
            type="select"
            name="report"
            value={selectedReport}
            onChangeEvent={(e: React.ChangeEvent<HTMLInputElement>) => { setSelectedReport(e.target.value) }}
            onBlurEvent={() => {}}
            touchedField={null}
            errorField={null}
            isDisabled={false}
            selectOptions={reports}
          />
        </Col>
      </Row>
    )
  }

  const getReportSection = () => {
    switch (selectedReport) {
      case MemberStatusByMonthReportName: {
        return getMemberStatusByMonthReport();
      }
      case OwedMembershipFeesReportName: {
        return getOwedMembershipFeesReport();
      }
      case MembersReportName: {
        return getMembersReport();
      }
    }
    return <></>;
  }

  return (
    <>
      <Row>
        <h2>Reportes</h2>
        {getReportSelector()}
      </Row>
      <Row>
        {selectedReport ? <h4>Parámetros</h4> : null}
        {getReportSection()}
      </Row>
    </>
  );
}
