import {Col, Row} from "react-bootstrap";
import {Operation} from "../../models/operation";
import ActivityForm from "../../components/forms/activity-form";
import {useToastConfiguration} from "../../App";
import {Activity} from "../../models/activity";
import {makeGetActivitiesCall} from "../../services/activity-service";
import {useLoaderData} from "react-router-dom";

// @ts-expect-error this is required
export const viewActivityLoader = async({ params }): Promise<Activity> => {
  const activities = (await makeGetActivitiesCall())?.data;
  return activities?.find((activity) => activity.id === params.activityId) as Activity;
};

export default function ViewActivity() {
  const activity = useLoaderData() as Activity;
  const { toastConfiguration } = useToastConfiguration();

  return (
    <Row>
      <Col md={{ span: 8, offset: 2 }}>
        <h1>Actividades</h1>
        <h2></h2>
        <ActivityForm activity={activity} operation={Operation.View} afterSubmitting={() => {}} toastConfiguration={toastConfiguration}/>
      </Col>
    </Row>
  )
}
