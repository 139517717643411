import {AxiosError, AxiosResponse} from "axios";
import axiosPrivate from "../models/axios/axios-private";
import {
  GET_MODULES_ENDPOINT,
  GET_ROLES_ENDPOINT,
  POST_ROLE_ENDPOINT
} from "../constants";
import {getEndpointResponse} from "../models/endpoint-response";
import { HttpRequestResponse } from "../models/axios/http-request-response";
import { Module } from "../models/authorization/module";
import {Role} from "../models/role";
import {CreateRoleDto} from "../models/dtos/create-role-dto";

export const makeGetModulesCall = async(): Promise<HttpRequestResponse<Module[]>> => {
  let response: AxiosResponse | AxiosError;
  try {
    response = await axiosPrivate.get(GET_MODULES_ENDPOINT);
  } catch (err: unknown) {
    response = err as AxiosError;
  }
  return getEndpointResponse(response);
};

export const makeGetRolesCall = async(): Promise<HttpRequestResponse<Role[]>> => {
  let response: AxiosResponse | AxiosError;
  try {
    response = await axiosPrivate.get(GET_ROLES_ENDPOINT);
  } catch (err: unknown) {
    response = err as AxiosError;
  }
  return getEndpointResponse(response);
};

export const makePostRoleCall = async(role: CreateRoleDto): Promise<HttpRequestResponse<CreateRoleDto>> => {
  let response: AxiosResponse | AxiosError;
  try {
    response = await axiosPrivate.post(POST_ROLE_ENDPOINT, role);
  } catch (err: unknown) {
    response = err as AxiosError;
  }
  return getEndpointResponse(response);
};
