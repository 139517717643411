import React, {PropsWithChildren, useState} from "react";
import { AuthContext } from "../context/auth-context";
import {UserLocalStorage} from "../models/user-local-storage";

export default function AuthProvider(props: PropsWithChildren) {
  const [user, setUser] = useState<UserLocalStorage | null>(null);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {props.children}
    </AuthContext.Provider>
  );
};
