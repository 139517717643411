import {AxiosResponse, HttpStatusCode} from "axios";
import axiosPrivate from "../models/axios/axios-private";
import {GET_VALUES_LISTS_ENDPOINT, POST_VALUES_LISTS_ENDPOINT} from "../constants";
import {LookupListValue} from "../models/lookup-list-value";

export const makeGetValuesListsCall = async(lookupListId?: string): Promise<AxiosResponse | null> => {
  let endpoint = GET_VALUES_LISTS_ENDPOINT;
  if (lookupListId) {
    endpoint = GET_VALUES_LISTS_ENDPOINT.concat(`?lookupListId=${lookupListId}`);
  }
  const response = await axiosPrivate.get(endpoint);
  if (response.status === HttpStatusCode.Ok) {
    return response;
  } else {
    console.log(
      `Error while trying to get values lists: ${response.statusText}`
    );
    return null;
  }
};

export const makePostValuesListsCall = async(request: LookupListValue): Promise<AxiosResponse | null> => {
  const response = await axiosPrivate.post(POST_VALUES_LISTS_ENDPOINT, request);
  if (response.status === HttpStatusCode.Created) {
    return response;
  } else {
    console.log(
      `Error while trying to create value list: ${response.statusText}`
    );
    return null;
  }
};
