import {Outlet} from "react-router-dom";
import {useToastConfiguration} from "../../App";

function Admin() {
  const { toastConfiguration } = useToastConfiguration();

  return (
    <>
      <h1>Administración</h1>
      <Outlet context={{ toastConfiguration }} />
    </>
  )
}

export default Admin;
