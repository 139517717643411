import {Button, Col, Form, Row} from "react-bootstrap";
import FormInput from "./form-input";
import {useFormik} from "formik";
import * as Yup from "yup";
import {makePostActivitiesCall} from "../../services/activity-service";
import {useLoaderData, useLocation} from "react-router-dom";
import {Sport} from "../../models/sport";
import {useEffect, useState} from "react";
import {KeyValuePair} from "../../models/key-value-pair";
import {FORM_SELECT_DEFAULT_OPTION_TEXT, TOAST_SUCCESSFUL_OPERATION_TITLE} from "../../constants";
import {ToastConfiguration} from "../../models/toast-configuration";
import {Operation} from "../../models/operation";
import {Activity} from "../../models/activity";

export interface Props {
  activity?: Activity,
  operation: string;
  afterSubmitting: () => void;
  toastConfiguration?: ToastConfiguration | null;
}

export default function ActivityForm(props: Props) {
  const getSportsResponse = useLoaderData() as Sport[];
  const location = useLocation();
  const [sportsKeyValueList, setSportsKeyValueList] = useState<KeyValuePair[]>([]);

  useEffect(() => {
    const keyValueList: KeyValuePair[] = [];
    if (props.operation === Operation.View) {
      keyValueList.push({ key: props.activity?.sportId as string, value: props.activity?.sport?.name ?? '' });
    }
    else {
      keyValueList.push({ value: FORM_SELECT_DEFAULT_OPTION_TEXT });
      getSportsResponse?.map((sport) => {
        keyValueList.push({ key: sport.id as string, value: sport.name });
      });
    }

    setSportsKeyValueList(keyValueList);
    const selectedSport = keyValueList.find((sport) => sport.key === location.state?.sportId)?.key;
    formik.initialValues.sportId = selectedSport!;
    formik.touched.sportId = true;
  }, []);

  const formik = useFormik({
    initialValues: {
      name: props.activity?.name ?? '',
      description: props.activity?.description ??'',
      sportId: props.activity?.sportId ?? '',
      monthlyPrice: props.activity?.monthlyPrice ?? '',
      scheduleDetails: props.activity?.scheduleDetails ?? '',
      location: props.activity?.location ?? '',
      responsiblePersonsFullNames: props.activity?.responsiblePersonsFullNames ?? '',
      comments: props.activity?.comments ?? '',
      isActive: true,
      isInsurance: props.activity?.isInsurance ?? false,
      isInsuranceRequired: props.activity?.isInsuranceRequired ?? false
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, 'Debe tener al menos 3 caracteres')
        .max(255, 'No puede tener más de 255 caracteres')
        .required('Es requerido'),
      description: Yup.string()
        .min(2, 'Debe tener al menos 2 caracteres')
        .required('Es requerido'),
      sportId: Yup.string().nullable(),
      monthlyPrice: Yup.number()
        .min(0, 'Debe ser mayor que $0,00')
        .required('Es requerido')
    }),
    onSubmit: async () => {
      props.toastConfiguration?.setShow(true);
      const response = await makePostActivitiesCall({
        id: undefined,
        name: formik.values.name,
        description: formik.values.description,
        sportId: formik.values.sportId,
        sport: undefined,
        monthlyPrice: Number(formik.values.monthlyPrice),
        scheduleDetails: formik.values.scheduleDetails,
        location: formik.values.location,
        responsiblePersonsFullNames: formik.values.responsiblePersonsFullNames,
        comments: formik.values.comments,
        isActive: true,
        isInsurance: formik.values.isInsurance,
        isInsuranceRequired: formik.values.isInsuranceRequired ?? false
      });
      if (response) {
        props.toastConfiguration?.setOperationSuccessful(true);
        props.toastConfiguration?.setTitle(TOAST_SUCCESSFUL_OPERATION_TITLE);
        props.toastConfiguration?.setBody('La actividad ha sido cargada exitosamente.');
        formik.resetForm();
        props.afterSubmitting();
      }
    }
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row>
        <Col xs={4}>
          <FormInput
            controlId="formGridSport"
            label="Deporte"
            type="select"
            name="sportId"
            value={formik.values.sportId}
            onChangeEvent={formik.handleChange}
            onBlurEvent={formik.handleBlur}
            touchedField={formik.touched.sportId}
            errorField={formik.errors.sportId}
            placeholder=""
            isDisabled={props.operation === Operation.View}
            selectOptions={sportsKeyValueList}
          />
        </Col>
        <Col xs={4}>
          <FormInput
            controlId="formGridName"
            label="Nombre de la actividad"
            type="text"
            name="name"
            value={formik.values.name}
            onChangeEvent={formik.handleChange}
            onBlurEvent={formik.handleBlur}
            touchedField={formik.touched.name}
            errorField={formik.errors.name}
            placeholder="Tenis - Menores"
            isDisabled={props.operation === Operation.View}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={8}>
          <FormInput
            controlId="formGridDescription"
            label="Descripción"
            type="text"
            name="description"
            value={formik.values.description}
            onChangeEvent={formik.handleChange}
            onBlurEvent={formik.handleBlur}
            touchedField={formik.touched.description}
            errorField={formik.errors.description}
            placeholder=""
            isDisabled={props.operation === Operation.View}
          />
        </Col>
        <Col xs={4}>
          <FormInput
            controlId="formGridMonthlyPrice"
            label="Abono mensual"
            type="number"
            name="monthlyPrice"
            value={formik.values.monthlyPrice}
            onChangeEvent={formik.handleChange}
            onBlurEvent={formik.handleBlur}
            touchedField={formik.touched.monthlyPrice}
            errorField={formik.errors.monthlyPrice}
            placeholder=""
            isDisabled={props.operation === Operation.View}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <FormInput
            controlId="formGridScheduleDetails"
            label="Días y horarios"
            type="text"
            name="scheduleDetails"
            value={formik.values.scheduleDetails}
            onChangeEvent={formik.handleChange}
            onBlurEvent={formik.handleBlur}
            touchedField={formik.touched.scheduleDetails}
            errorField={formik.errors.scheduleDetails}
            placeholder=""
            isDisabled={props.operation === Operation.View}
          />
        </Col>
        <Col xs={6}>
          <FormInput
            controlId="formGridLocation"
            label="Lugar de realización"
            type="text"
            name="location"
            value={formik.values.location}
            onChangeEvent={formik.handleChange}
            onBlurEvent={formik.handleBlur}
            touchedField={formik.touched.location}
            errorField={formik.errors.location}
            placeholder=""
            isDisabled={props.operation === Operation.View}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <FormInput
            controlId="formGridResponsiblePersonsFullNames"
            label="Profesores y/o personas responsables"
            type="text"
            name="responsiblePersonsFullNames"
            value={formik.values.responsiblePersonsFullNames}
            onChangeEvent={formik.handleChange}
            onBlurEvent={formik.handleBlur}
            touchedField={formik.touched.responsiblePersonsFullNames}
            errorField={formik.errors.responsiblePersonsFullNames}
            placeholder=""
            isDisabled={props.operation === Operation.View}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormInput
            controlId="formGridComments"
            label="Comentarios y/o notas adicionales"
            type="textarea"
            name="comments"
            value={formik.values.comments}
            onChangeEvent={formik.handleChange}
            onBlurEvent={formik.handleBlur}
            touchedField={formik.touched.comments}
            errorField={formik.errors.comments}
            placeholder=""
            isDisabled={props.operation === Operation.View}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          { props.operation === Operation.View
              ? <p>Requiere seguro: {props.activity?.isInsuranceRequired ? "Sí" : "No"}</p>
              : (
                <FormInput
                  controlId="formGridIsInsurance"
                  label="Requiere seguro"
                  type="checkbox"
                  name="isInsuranceRequired"
                  value={""}
                  onChangeEvent={() => { formik.setFieldValue('isInsuranceRequired', !formik.values.isInsuranceRequired) }}
                  onBlurEvent={formik.handleBlur}
                  touchedField={formik.touched.isInsuranceRequired}
                  errorField={formik.errors.isInsuranceRequired}
                  placeholder=""
                  isDisabled={props.operation === Operation.View}
                />
            )
          }
        </Col>
      </Row>
      { props.operation !== Operation.View ? <Button variant="primary" type="submit">Cargar</Button> : <></> }
    </Form>
  )
}
