import AddProductForm from "../../components/forms/add-product-form";
import {useToastConfiguration} from "../../App";

export default function AddMembershipFee() {
  const { toastConfiguration } = useToastConfiguration();

  return (
    <>
      <h2>Crear cuotas sociales</h2>
      <AddProductForm discriminator="MembershipFee" toastConfiguration={toastConfiguration} />
    </>
  )
}
