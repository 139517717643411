import React, { useEffect } from 'react';
import {AuthContext} from "../context/auth-context";
import {LoginRequest} from "../models/web/login-request";
import {makePostLoginCall} from "../services/user-service";
import {UserLocalStorage} from "../models/user-local-storage";
import {useLocalStorage} from "./use-local-storage";
import {MEMBER_ROLE_ID} from "../constants";
import {verifyLoggedInConditionsAreMet} from "../helpers";

export const useAuth = () => {
  const { user, setUser } = React.useContext(AuthContext);
  const { setItem, getItem, removeItem } = useLocalStorage();

  useEffect(() => {
    const user = getItem('user');
    if (user) {
      setUser(JSON.parse(user));
    }
  }, []);

  const login = async (loginRequest: LoginRequest): Promise<boolean> => {
    const response = await makePostLoginCall(loginRequest);
    if (!response.data || !response?.isSuccessful) {
      return false;
    }

    if (response.data.user.role.id === MEMBER_ROLE_ID) {
      return false;
    }

    const loginResponse = response.data;
    const userLocalStorage: UserLocalStorage = {
      id: loginResponse.user.id,
      role: loginResponse.user.role,
      token: loginResponse.token,
      username: loginResponse.user.username,
    }
    setUser(userLocalStorage);
    setItem('user', JSON.stringify(userLocalStorage));
    setItem('token', loginResponse.token);
    setItem('refreshToken', loginResponse.refreshToken);
    return true;
  };

  const logout = async (): Promise<void> => {
    setUser(null);
    removeItem('user');
    removeItem('token');
    removeItem('refreshToken');
  };

  const isLoggedIn = (): boolean => {
    return verifyLoggedInConditionsAreMet();
  }

  return { user, login, logout, isLoggedIn };
};
