import {Outlet} from "react-router-dom";
import {useToastConfiguration} from "../../../App";

export default function Roles() {
  const { toastConfiguration } = useToastConfiguration();

  return (
    <>
      <h1>Roles</h1>
      <Outlet context={{ toastConfiguration }} />
    </>
  )
}
