import FormInput from "./form-input";
import React, {Dispatch, SetStateAction} from "react";

interface Props {
  selectedYear: number;
  setSelectedYear: Dispatch<SetStateAction<number>>;
}

export default function YearDropdown(props: Props) {
  return (
    <FormInput
      controlId="year"
      label="Año"
      type="select"
      name="year"
      value={props.selectedYear}
      onChangeEvent={(e: React.ChangeEvent<HTMLInputElement>) => { props.setSelectedYear(Number(e.target.value)) }}
      onBlurEvent={() => {}}
      touchedField={null}
      errorField={null}
      isDisabled={false}
      selectOptions={[
        {
          key: '',
          value: 'Todos'
        },
        {
          key: '2020',
          value: '2020'
        },
        {
          key: '2021',
          value: '2021'
        },
        {
          key: '2022',
          value: '2022'
        },
        {
          key: '2023',
          value: '2023'
        },
        {
          key: '2024',
          value: '2024'
        }
      ]}
    />
  )
}
