import {Button, Form, Modal} from "react-bootstrap";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import { Member } from "../models/member/member";
import {getMemberFullName} from "../helpers";
import {makeGetValuesListsCall} from "../services/values-lists-service";
import {
  LOOKUP_LIST_ID_PAYMENT_METHOD,
  MEMBERSHIP_FEE_DISCRIMINATOR,
  TOAST_SUCCESSFUL_OPERATION_TITLE
} from "../constants";
import {LookupListValue} from "../models/lookup-list-value";
import {KeyValuePair} from "../models/key-value-pair";
import FormInput from "./forms/form-input";
import {useFormik} from "formik";
import * as Yup from "yup";
import {makePostPaymentsCall} from "../services/payment-service";
import {ToastConfiguration} from "../models/toast-configuration";
import {HttpStatusCode} from "axios";
import {PeriodicProduct} from "../models/periodic-product";
import { PartialPayment } from "../models/partial-payment";
import {ProductPayment, ProductPaymentNew} from "../models/membership-fee/product-payment";
import CurrencyData from "./currency-data";

export interface Props {
  member: Member;
  productFeesToPay: PeriodicProduct[];
  paymentsToMake?: ProductPayment[] | ProductPaymentNew[];
  discriminator: string;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  toastConfiguration?: ToastConfiguration | null;
  operationCompleteCallback: () => void;
  partialPayments?: PartialPayment[];
}

export default function PaymentPopup(props: Props) {
  const [paymentMethodKeyValueList, setPaymentMethodKeyValueList] = useState<KeyValuePair[]>([]);

  const formik = useFormik({
    initialValues: {
      methodId: '',
      comments: ''
    },
    validationSchema: Yup.object({
      methodId: Yup.string().required('Es requerido'),
      comments: Yup.string()
    }),
    onSubmit: async () => {
      const payload = {
        memberId: props.member.id!,
        paymentDetails: props.paymentsToMake!.map((productPayment) => {
          const partialPayment = props.partialPayments?.find(p => p.productId === productPayment.product.id);
          return {
            productId: productPayment.product.id!,
            price: partialPayment ? partialPayment.partialPayment : productPayment.remainingPriceToPay,
            discriminator: props.discriminator
          }
        }),
        methodId: formik.values.methodId,
        date: new Date(),
        isCompleted: true,
        comments: formik.values.comments
      };
      const response = await makePostPaymentsCall(payload);
      if (response) {
        const message = props.discriminator === MEMBERSHIP_FEE_DISCRIMINATOR
          ? 'Las cuotas sociales han sido marcadas como abonadas.'
          : 'El pago ha sido registrado exitosamente.'
        props.setShow(false);
        props.toastConfiguration?.setShow(true);
        if (response.status === HttpStatusCode.Created || response.status === HttpStatusCode.Ok) {
          props.toastConfiguration?.setOperationSuccessful(true);
          props.toastConfiguration?.setTitle(TOAST_SUCCESSFUL_OPERATION_TITLE);
          props.toastConfiguration?.setBody(message);
          props.operationCompleteCallback();
        }
      }
    }
  });

  useEffect(() => {
    const getPaymentMethods = async () => {
      const paymentMethodListValues = (await makeGetValuesListsCall(LOOKUP_LIST_ID_PAYMENT_METHOD))?.data[0].lookupListValues as LookupListValue[];
      const paymentMethodKeyValueList: KeyValuePair[] = [];

      paymentMethodListValues.map((paymentMethod) => {
        paymentMethodKeyValueList.push({ key: paymentMethod.id, value: paymentMethod.value });
      });
      setPaymentMethodKeyValueList(paymentMethodKeyValueList);
      formik.values.methodId = paymentMethodKeyValueList.at(0)?.key!;
      formik.touched.methodId = true;
    }

    getPaymentMethods().then();
  }, []);

  const getProductFeesToPayListItems = () => {
    return props.paymentsToMake?.map((productPayment) => {
      const partialPayment = props.partialPayments?.find(p => p.productId === productPayment.product.id);
      return (
        <li key={productPayment.product.id}>
          {
            props.discriminator === MEMBERSHIP_FEE_DISCRIMINATOR
              ? productPayment.product.shortName
              : productPayment.product.name
          } (<CurrencyData value={ partialPayment ? partialPayment.partialPayment : productPayment.remainingPriceToPay } />)
          <strong>
          {
            partialPayment
              ? ' - PAGO PARCIAL'
              : ''
          }
          </strong>
        </li>
      )
    })
  }

  const getProductFeesToPayList = () => {
    return (
      <ul>
        {getProductFeesToPayListItems()}
      </ul>
    )
  }

  return (
    <Modal show={props.show} onHide={() => props.setShow(false)}>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar acción</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{
            props.discriminator === MEMBERSHIP_FEE_DISCRIMINATOR
              ? 'Está apunto de marcar las siguientes cuotas sociales como abonadas. Por favor, revise y complete los siguientes campos.'
              : 'Está apunto de marcar las siguientes períodos de actividades como abonados. Por favor, revise y complete los siguientes campos.'
          }</p>
          <p>Socio: {getMemberFullName(props.member)} (N° {props.member.memberNumber}).</p>
          <p>{
            props.discriminator === MEMBERSHIP_FEE_DISCRIMINATOR
              ? 'Cuotas sociales:'
              : 'Períodos de actividad:'
          }</p>
          {getProductFeesToPayList()}
          <FormInput
            controlId="formGridMethodId"
            label="Método de pago"
            type="select"
            name="methodId"
            value={formik.values.methodId}
            onChangeEvent={formik.handleChange}
            onBlurEvent={formik.handleBlur}
            touchedField={formik.touched.methodId}
            errorField={formik.errors.methodId}
            placeholder=""
            isDisabled={false}
            isSelectWithValueAlreadySelected={true}
            selectOptions={paymentMethodKeyValueList}
          />
          <FormInput
            controlId="formGridComments"
            label="Comentarios"
            type="text"
            name="comments"
            value={formik.values.comments}
            onChangeEvent={formik.handleChange}
            onBlurEvent={formik.handleBlur}
            touchedField={formik.touched.comments}
            errorField={formik.errors.comments}
            placeholder=""
            isDisabled={false}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            props.setShow(false);
          }}>Cancelar</Button>
          <Button variant="primary" type="submit">Confirmar</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
