import {Button, Table} from "react-bootstrap";
import {MembershipFee} from "../models/membership-fee/membership-fee";
import {getDate} from "../helpers";
import {DEFAULT_SHORT_DATE_FORMAT, MEMBERSHIP_FEE_DISCRIMINATOR} from "../constants";
import {Link} from "react-router-dom";
import React from "react";
import {PeriodicProduct} from "../models/periodic-product";
import {ActivityFee} from "../models/activity-fee";
import CurrencyData from "./currency-data";

interface Props {
  discriminator: string;
  periodicProducts: PeriodicProduct[];
  deleteFunction: (id: string) => void;
}

export default function PeriodicProductFeesList(props: Props) {
  // Table
  const getTable = (): JSX.Element => {
    return (
      <Table responsive striped bordered hover>
        <thead>
        <tr>
          <th>{ props.discriminator === MEMBERSHIP_FEE_DISCRIMINATOR ? 'Cuota social' : 'Arancel'}</th>
          <th>Valor</th>
          <th>Fecha de inicio de vigencia</th>
          <th>Fecha de vencimiento</th>
          <th>Fecha de fin de vigencia</th>
          <th>Opciones</th>
        </tr>
        </thead>
        {getTableRows()}
      </Table>
    )
  }

  const getTableRows = (): JSX.Element => {
    if (!props.periodicProducts) {
      return <></>
    }

    const periodicProductFeesRows = props.periodicProducts.map((periodicProductFee: MembershipFee | ActivityFee) => (
      <tr key={periodicProductFee.id}>
        <td>{ props.discriminator === MEMBERSHIP_FEE_DISCRIMINATOR ? periodicProductFee.shortName : periodicProductFee.name }</td>
        <td><CurrencyData value={periodicProductFee.price} /></td>
        <td>{getDate(periodicProductFee.startDate, DEFAULT_SHORT_DATE_FORMAT, true)}</td>
        <td>{getDate(periodicProductFee.dueDate, DEFAULT_SHORT_DATE_FORMAT, true)}</td>
        <td>{getDate(periodicProductFee.endDate, DEFAULT_SHORT_DATE_FORMAT, true)}</td>
        <td>
          <div className="d-flex justify-content-center">
            <Button type="button" variant="primary" className="ml-2 mr-3">
              <Link to={`../edit/${periodicProductFee.id}`} className="link-button-primary">Editar</Link>
            </Button>
            <Button type="button" variant="danger" className="ml-2 mr-3" onClick={() => props.deleteFunction(periodicProductFee.id!)}
                    disabled={(periodicProductFee.paymentsCount ?? 0) > 0}>
              <i className="fa-regular fa-trash-can"></i>
            </Button>
          </div>
        </td>
      </tr>
    ));

    return (
      <tbody>{periodicProductFeesRows}</tbody>
    )
  }

  return getTable();
}
