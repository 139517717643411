import axios from 'axios';
import {makePostRefreshTokenCall} from "../../services/user-service";
import {useLocalStorage} from "../../hooks/use-local-storage";

const axiosPrivate = axios.create({
  headers: {
    "Content-Type": "application/json"
  }
});

axiosPrivate.interceptors.request.use(
  async (config) => {
    const localStorage = useLocalStorage();
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  async (error) => await Promise.reject(error)
)

axiosPrivate.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const oldToken = localStorage.getItem('token') as string;
      const oldRefreshToken: string = localStorage.getItem('refreshToken') as string;
      if (!oldToken || !oldRefreshToken) {
        return Promise.reject(error);
      }

      const response = await makePostRefreshTokenCall(oldToken, oldRefreshToken);
      if (!response.data || !response.isSuccessful) {
        return Promise.reject(error);
      }

      const newToken = response.data.token;
      const newRefreshToken = response.data.refreshToken;
      localStorage.setItem('token', newToken);
      localStorage.setItem('refreshToken', newRefreshToken);
      axiosPrivate.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;

      return axiosPrivate(originalRequest);
    }
    return Promise.reject(error);
  }
)

export default axiosPrivate;
