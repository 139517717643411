import {Outlet} from "react-router-dom";
import {useToastConfiguration} from "../../../App";

function Settings() {
  const { toastConfiguration } = useToastConfiguration();

  return (
    <>
      <h1>Usuarios</h1>
      <Outlet context={{ toastConfiguration }} />
    </>
  )
}

export default Settings;
