import {Outlet} from "react-router-dom";
import {useToastConfiguration} from "../../App";

function MembershipFees() {
  const { toastConfiguration } = useToastConfiguration();

  return (
    <Outlet context={{toastConfiguration}} />
  )
}

export default MembershipFees;
