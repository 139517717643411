import {Button, Col, Form, Row} from "react-bootstrap";
import FormInput from "./form-input";
import {useFormik} from "formik";
import * as Yup from "yup";
import {makePostSportsCall} from "../../services/sport-service";
import {ToastConfiguration} from "../../models/toast-configuration";
import {BUTTON_CREATE_NEW_LABEL, TOAST_FAILED_OPERATION_TITLE, TOAST_SUCCESSFUL_OPERATION_TITLE} from "../../constants";

export interface Props {
  afterSubmitting: () => void;
  toastConfiguration?: ToastConfiguration | null;
}

export default function SportForm(props: Props) {
  const formik = useFormik({
    initialValues: {
      name: ''
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, 'Debe tener al menos 3 caracteres')
        .max(100, 'No puede tener más de 100 caracteres')
        .required('Es requerido')
    }),
    onSubmit: async () => {
      props.toastConfiguration?.setShow(true);
      const response = await makePostSportsCall({
        name: formik.values.name
      });
      if (response.isSuccessful) {
        props.toastConfiguration?.setOperationSuccessful(true);
        props.toastConfiguration?.setTitle(TOAST_SUCCESSFUL_OPERATION_TITLE);
        props.toastConfiguration?.setBody('El deporte ha sido cargado exitosamente.');
        formik.resetForm();
        props.afterSubmitting();
      }
      else {
        props.toastConfiguration?.setOperationSuccessful(false);
        props.toastConfiguration?.setTitle(TOAST_FAILED_OPERATION_TITLE);
        props.toastConfiguration?.setBody('El deporte no ha podido ser cargado.');
      }
    }
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row>
        <Col xs={6}>
          <FormInput
            controlId="formGridName"
            label="Nombre del deporte"
            type="text"
            name="name"
            value={formik.values.name}
            onChangeEvent={formik.handleChange}
            onBlurEvent={formik.handleBlur}
            touchedField={formik.touched.name}
            errorField={formik.errors.name}
            placeholder="Tenis"
            isDisabled={false}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <div className="d-flex justify-content-center">
            <Button variant="primary" type="submit">{BUTTON_CREATE_NEW_LABEL}</Button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}
