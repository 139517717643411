import {Link, useLoaderData} from "react-router-dom";
import {Sport} from "../models/sport";
import React, {useState} from "react";
import {makeDeleteSportCall, makeGetSportsCall} from "../services/sport-service";
import {Button, Col, Row, Table} from "react-bootstrap";
import SportForm from "../components/forms/sport-form";
import {useToastConfiguration} from "../App";
import {verifyLoggedInConditionsAreMet} from "../helpers";

export const listSportsLoader = async(): Promise<Sport[]> => {
  if (!verifyLoggedInConditionsAreMet()) {
    return [];
  }
  return (await makeGetSportsCall()).data ?? [];
};

export default function Sports() {
  const getSportsResponse = useLoaderData() as Sport[];
  const [sportsResponse, setSportsResponse] = useState<Sport[]>(getSportsResponse);
  const { toastConfiguration } = useToastConfiguration();

  const refreshTable = async (): Promise<void> => {
    setSportsResponse((await makeGetSportsCall()).data ?? []);
  }

  const deleteSport = async (sportId: string) => {
    const succeeded = await makeDeleteSportCall(sportId);
    if (succeeded) {
      setSportsResponse(sportsResponse.filter((sport) => sport.id !== sportId));
    }
  }

  const getTableRows = (): JSX.Element => {
    if (!getSportsResponse) {
      return <></>
    }

    const sportsRows = sportsResponse?.length > 0 && sportsResponse.map((sport: Sport) => (
      <tr key={sport.id}>
        <td>{sport.name}</td>
        <td>
          <div className="d-flex justify-content-center">
            <Button type="button" variant="primary">
              <Link to={`../activities/add`} state={{ sportId: sport.id }} className="link-button-primary">Añadir actividad</Link>
            </Button>
            <Button type="button" variant="danger" className="ml-2 mr-3" onClick={() => deleteSport(sport.id!)}
                    disabled={(sport.activities?.length ?? 0) > 0}>
              <i className="fa-regular fa-trash-can"></i>
            </Button>
          </div>
        </td>
      </tr>
    ));

    return (
      <tbody>{sportsRows}</tbody>
    )
  }

  const getTable = (): React.ReactNode => {
    return (
      <Table responsive striped bordered hover>
        <thead>
        <tr>
          <th>Deporte</th>
          <th>Opciones</th>
        </tr>
        </thead>
        {getTableRows()}
      </Table>
    )
  }

  return (
    <Row>
      <Col md={{ span: 8, offset: 2 }}>
        <Row>
          <h2>Deportes</h2>
          <Col>
            <h3>Cargar deporte</h3>
            <SportForm afterSubmitting={refreshTable} toastConfiguration={toastConfiguration} />
          </Col>
          <Col>
            <h3>Listado</h3>
            {getTable()}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
