import {AxiosError, AxiosResponse} from "axios";
import axiosPrivate from "../models/axios/axios-private";
import {
  GET_USERS_ENDPOINT, POST_USER_ENDPOINT, V2_LOGIN_ENDPOINT, V2_PATCH_USER_ENDPOINT, V2_REFRESH_TOKEN_ENDPOINT
} from "../constants";
import {LoginRequest} from "../models/web/login-request";
import {UpdateUserDto} from "../models/web/update-user-dto";
import {User} from "../models/user";
import {getEndpointResponse} from "../models/endpoint-response";
import { HttpRequestResponse } from "../models/axios/http-request-response";
import {CreateUserDto} from "../models/web/create-user-dto";
import {LoginResponse} from "../models/web/login-response";

export const makeGetUsersCall = async(): Promise<HttpRequestResponse<User[]>> => {
  let response: AxiosResponse | AxiosError;
  try {
    response = await axiosPrivate.get(GET_USERS_ENDPOINT);
  } catch (err: unknown) {
    response = err as AxiosError;
  }
  return getEndpointResponse(response);
};

export const makePostUserCall = async(user: CreateUserDto): Promise<HttpRequestResponse<User>> => {
  let response: AxiosResponse | AxiosError;
  try {
    response = await axiosPrivate.post(POST_USER_ENDPOINT, user);
  } catch (err: unknown) {
    response = err as AxiosError;
  }
  return getEndpointResponse(response);
};

export const makePatchUserCall = async(user: UpdateUserDto): Promise<HttpRequestResponse<User>> => {
  let response: AxiosResponse | AxiosError;
  try {
    const request = [
      {
        "op": "replace",
        "path": "/Password",
        "value": `${user.password}`
      },
      {
        "op": "replace",
        "path": "/NewPassword",
        "value": `${user.newPassword}`
      }
    ]
    response = await axiosPrivate.patch(V2_PATCH_USER_ENDPOINT.replace('{UserId}', user.id), request)
  } catch (err: unknown) {
    response = err as AxiosError;
  }
  return getEndpointResponse(response);
};

export const makePostLoginCall = async(request: LoginRequest): Promise<HttpRequestResponse<LoginResponse>> => {
  let response: AxiosResponse | AxiosError;
  try {
    response = await axiosPrivate.post(V2_LOGIN_ENDPOINT, request);
  } catch (err: unknown) {
    response = err as AxiosError;
  }
  return getEndpointResponse(response);
};

export const makePostRefreshTokenCall = async(token: string, refreshToken: string): Promise<HttpRequestResponse<LoginResponse>> => {
  let response: AxiosResponse | AxiosError;
  try {
    response = await axiosPrivate.post(V2_REFRESH_TOKEN_ENDPOINT, {
      token,
      refreshToken
    });
  } catch (err: unknown) {
    response = err as AxiosError;
  }
  return getEndpointResponse(response);
};
