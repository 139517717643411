import {useEffect} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {LOGIN_PAGE_ROUTE} from "../constants";
import {useAuth} from "../hooks/use-auth";
import {useLocalStorage} from "../hooks/use-local-storage";

interface Props {
  children: JSX.Element;
}

const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

const InactivityLogoutTimer = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const localStorage = useLocalStorage();
  const auth = useAuth();

  const checkForInactivity = () => {
    const expireTimeString = localStorage.getItem('expireTime');
    const expireTime = expireTimeString ? parseInt(expireTimeString) : 0;

    if (expireTime < Date.now() && location.pathname !== '/') {
      auth.logout();
      localStorage.removeItem('expireTime');
      navigate(LOGIN_PAGE_ROUTE);
    }
  };

  const updateExpiryTime = () => {
    const expireTime = Date.now() + 60 * 60 * 1000; // 1 hour
    localStorage.setItem('expireTime', expireTime.toString());
  };

  useEffect(() => {
    const interval = setInterval(() => {
      checkForInactivity();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    updateExpiryTime();
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        updateExpiryTime();
      })
    });

    return () => {
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, () => {
          updateExpiryTime();
        })
      });
    };
  }, []);

  return props.children;
};

export default InactivityLogoutTimer;
