import {Card, Col} from "react-bootstrap";
import parse from 'html-react-parser';

export interface Props {
  colKey: string;
  cardTitle: string;
  cardText: string;
}

function StatsCard(props: Props)  {
  return (
    <Col key={props.colKey}>
      <Card>
        <Card.Body>
          <Card.Title>{props.cardTitle}</Card.Title>
          <Card.Text>{parse(props.cardText)}</Card.Text>
        </Card.Body>
      </Card>
    </Col>
  )
}

export default StatsCard;
