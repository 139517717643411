import {AxiosError, AxiosResponse} from "axios";
import axiosPrivate from "../models/axios/axios-private";
import {V2_POST_SPORTS_ENDPOINT, V2_PATCH_SPORTS_ENDPOINT, V2_GET_SPORTS_ENDPOINT} from "../constants";
import {Sport} from "../models/sport";
import {HttpRequestResponse} from "../models/axios/http-request-response";
import {getEndpointResponse} from "../models/endpoint-response";

export const makeGetSportsCall = async(): Promise<HttpRequestResponse<Sport[]>> => {
  let response: AxiosResponse | AxiosError;
  try {
    response = await axiosPrivate.get(V2_GET_SPORTS_ENDPOINT);
  } catch (err: unknown) {
    response = err as AxiosError;
  }
  return getEndpointResponse(response);
};

export const makeDeleteSportCall = async(sportId: string): Promise<boolean> => {
  let response: AxiosResponse | AxiosError;
  try {
    response = await axiosPrivate.delete(V2_PATCH_SPORTS_ENDPOINT.replace('{SportId}', sportId));
  } catch (err: unknown) {
    response = err as AxiosError;
  }
  return getEndpointResponse(response).isSuccessful;
};


export const makePostSportsCall = async(request: Sport): Promise<HttpRequestResponse<Sport[]>> => {
  let response: AxiosResponse | AxiosError;
  try {
    response = await axiosPrivate.post(V2_POST_SPORTS_ENDPOINT, request);
  } catch (err: unknown) {
    response = err as AxiosError;
  }
  return getEndpointResponse(response);
};
