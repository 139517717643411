import {Row} from "react-bootstrap";
import StatsCard from "../components/stats-card";
import {useAuth} from "../hooks/use-auth";
import {makeGetStatsCall} from "../services/stats-service";
import {useLoaderData} from "react-router-dom";
import {StatsOutcomeModel} from "../models/web/stats-outcome-model";
import {verifyLoggedInConditionsAreMet} from "../helpers";

export const homeLoader = async(): Promise<StatsOutcomeModel[]> => {
  if (!verifyLoggedInConditionsAreMet()) {
    return [];
  }
  return (await makeGetStatsCall()).data ?? [];
};

function Home() {
  const { user } = useAuth();
  const stats = useLoaderData() as StatsOutcomeModel[];

  return (
    <div>
      <p>¡Bienvenido/a, <b>{user?.username}</b>!</p>
      <Row xs={1} md={2} className="g-4">
        {
          stats && stats.map((item) => (
            <StatsCard key={item.key} cardText={item.description} cardTitle={item.value} colKey={item.key}/>
          ))
        }
      </Row>
    </div>
  )
}

export default Home;
