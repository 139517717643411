import {useAuth} from "../hooks/use-auth";
import {Navigate, useLocation} from "react-router-dom";
import React from "react";
import {HOME_PAGE_ROUTE} from "../constants";
import {userHasPermission, userHasPermissionToAccessModule} from "../helpers";

export interface Props {
  children: JSX.Element;
  moduleName: string;
  taskName?: string;
  tasksNames?: string[];
}

export default function AuthorizedRoute(props: Props): JSX.Element {
  const { user, isLoggedIn } = useAuth();
  const location = useLocation();

  if (!isLoggedIn()) {
    return <Navigate to={HOME_PAGE_ROUTE} state={{ from: location }} replace />;
  }

  let hasPermissions = true;
  if (!user) {
    hasPermissions = false;
  }
  else {
    if (props.tasksNames && props.tasksNames.length > 0) {
      if (!userHasPermissionToAccessModule(user!, props.moduleName, props.tasksNames)) {
        hasPermissions = false;
      }
    }
    else if (props.taskName) {
      if (!userHasPermission(user!, props.moduleName, props.taskName)) {
        hasPermissions = false;
      }
    }
  }

  return hasPermissions ? props.children : <Navigate to={HOME_PAGE_ROUTE} state={{ from: location }} replace />;
}
