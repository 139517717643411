import * as Yup from 'yup';

export const createUserSchema = Yup.object({
  username: Yup
    .string()
    .required('Es requerido'),
  password: Yup
    .string()
    .required('Es requerido')
    .min(5, 'La contraseña debe tener al menos 5 caracteres.')
    .max(255, 'La contraseña no puede tener más de 255 caracteres.'),
  passwordConfirmation: Yup
    .string()
    .required('Es requerido')
    .min(5, 'La contraseña debe tener al menos 5 caracteres.')
    .max(255, 'La contraseña no puede tener más de 255 caracteres.')
    .test(
      'password-confirmation',
      'Las contraseña no coincide con la que ingresó anteriormente.',
      (value, context) => value === context.parent.password
    ),
  roleId: Yup
    .string()
    .required('Debe seleccionar un rol')
})
