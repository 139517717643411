import {Col, Row, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {makeGetValuesListsCall} from "../../services/values-lists-service";
import {useLoaderData} from "react-router-dom";
import {LookupList} from "../../models/lookup-list";
import {LookupListValue} from "../../models/lookup-list-value";
import ValueListForm from "../../components/forms/value-list-form";
import {KeyValuePair} from "../../models/key-value-pair";
import {FORM_SELECT_DEFAULT_OPTION_TEXT} from "../../constants";
import {useToastConfiguration} from "../../App";

export const valuesListsLoader = async(): Promise<void> => {
  return (await makeGetValuesListsCall())?.data;
};

export default function Values(): JSX.Element {
  const getValuesListsResponse = useLoaderData() as LookupList[];
  const [valuesListsResponse, setValuesListsResponse] = useState<LookupList[]>(getValuesListsResponse);
  const [valuesListsKeyValueList, setValuesListsKeyValueList] = useState<KeyValuePair[]>([]);
  const { toastConfiguration } = useToastConfiguration();


  useEffect(() => {
    const keyValueList: KeyValuePair[] = [ { value: FORM_SELECT_DEFAULT_OPTION_TEXT }];
    getValuesListsResponse.map((lookupList) => {
      keyValueList.push({ key: lookupList.id as string, value: lookupList.name });
    });
    setValuesListsKeyValueList(keyValueList);
  }, []);

  // Table
  const getValuesListsTable = (): JSX.Element => {
    return (
      <Table striped bordered hover>
        <thead>
        <tr>
          <th>Nombre de la lista</th>
          <th>Valor</th>
        </tr>
        </thead>
        {getValuesListsTableTableRows()}
      </Table>
    )
  }

  const getValuesListsTableTableRows = (): JSX.Element => {
    if (!valuesListsResponse) {
      return <></>
    }

    const lookupListsRows = valuesListsResponse.map((lookupList: LookupList) => (
      lookupList.lookupListValues.map((lookupListValue: LookupListValue) => {
        return (
          <tr key={lookupListValue.id}>
            <td>{lookupList.name}</td>
            <td>{lookupListValue.value}</td>
          </tr>
        )
      })
    ));

    return (
      <tbody>{lookupListsRows}</tbody>
    )
  }

  const refreshTable = async (): Promise<void> => {
    setValuesListsResponse((await makeGetValuesListsCall())?.data);
  }

  return (
    <Row>
      <h2>Listas de valores</h2>
      <Col>
        <h3>Cargar valor</h3>
        <ValueListForm valuesLists={valuesListsKeyValueList} afterSubmitting={refreshTable} toastConfiguration={toastConfiguration} />
      </Col>
      <Col>
        <h3>Listas de valores</h3>
        {getValuesListsTable()}
      </Col>
    </Row>
  )
}
