import { AxiosError, AxiosResponse } from 'axios';
import { HttpRequestResponse } from './axios/http-request-response';

export const isInstanceOfAxiosResponse = (response: any): response is AxiosResponse => 'data' in response;

export const getEndpointResponse = <T>(axiosResponse: AxiosResponse | AxiosError): HttpRequestResponse<T> =>
  isInstanceOfAxiosResponse(axiosResponse)
    ? {
      message: axiosResponse.data.message,
      statusCode: axiosResponse.status,
      data: axiosResponse.data.result ?? axiosResponse.data,
      isSuccessful: axiosResponse.status >= 200 && axiosResponse.status < 300
    }
    : {
      message: (axiosResponse.response?.data as { message: string }).message,
      statusCode: axiosResponse.response?.status ?? 500,
      data: null,
      isSuccessful: false
    };
