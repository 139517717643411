import * as Yup from "yup";

export const MemberSchema = Yup.object({
  firstName: Yup.string()
    .min(3, 'Debe tener al menos 3 caracteres')
    .max(100, 'No puede tener más de 100 caracteres')
    .required('Es requerido'),
  lastName: Yup.string()
    .min(3, 'Debe tener al menos 3 caracteres')
    .max(100, 'No puede tener más de 100 caracteres')
    .required('Es requerido'),
  documentNumber: Yup.number()
    .min(1, 'No puede ser un entero negativo')
    .max(100000000, 'No puede ser más de 100 millones')
    .required('Es requerido'),
  birthdate: Yup.date()
    .min(new Date("1900-01-01 00:00:00.000 +00:00"), 'No puede ser una fecha tan antigua')
    .max(new Date(), 'Tiene que haber nacido en el pasado')
    .required('Es requerido'),
  genderId: Yup.string()
    .required('Es requerido'),
  address: Yup.object().shape({
    street: Yup.string().required('Es requerido'),
    number: Yup.number()
      .min(1, 'No puede ser un entero negativo')
      .required('Es requerido'),
    city: Yup.string().required('Es requerido'),
    postcode: Yup.string()
      .min(4, 'Debe tener al menos 4 caracteres')
      .required('Es requerido'),
    state: Yup.string().required('Es requerido'),
    country: Yup.string().required('Es requerido'),
    homePhoneNumber: Yup.string()
      .min(7, 'Debe tener al menos 7 caracteres')
      .max(30, 'No puede tener más de 30 caracteres'),
    mobilePhoneNumber: Yup.string()
      .min(7, 'Debe tener al menos 7 caracteres')
      .max(30, 'No puede tener más de 30 caracteres')
      .required('Es requerido')
  }),
  membershipDate: Yup.date()
    .min(new Date("1900-01-01 00:00:00.000 +00:00"), 'No puede ser una fecha tan antigua')
    .required('Es requerido')
});
