import {
  makeDeletePeriodicProductFeeCall,
  makeGetActivitiesFeesCall
} from "../../services/product-service";
import {useLoaderData} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Row} from "react-bootstrap";
import {ActivityFee} from "../../models/activity-fee";
import PeriodicProductFeesList from "../../components/periodic-product-fees-list";
import {ACTIVITY_FEE_DISCRIMINATOR, FORM_SELECT_DEFAULT_OPTION_TEXT} from "../../constants";
import FormInput from "../../components/forms/form-input";
import { Activity } from "../../models/activity";
import {makeGetActivitiesCall} from "../../services/activity-service";
import {KeyValuePair} from "../../models/key-value-pair";
import YearDropdown from "../../components/forms/year-dropdown";

export const listActivitiesFeesLoader = async(): Promise<[ActivityFee[], Activity[]]> => {
  const activitiesFees = (await makeGetActivitiesFeesCall())?.data ?? [];
  const activities = (await makeGetActivitiesCall())?.data ?? [];
  return [activitiesFees, activities]
};

export default function ListActivitiesFees() {
  const getActivitiesFeesResponse = (useLoaderData() as [ActivityFee[], Activity[]])[0];
  const getActivitiesResponse = (useLoaderData() as [ActivityFee[], Activity[]])[1];
  const [activitiesFees, setActivitiesFees] = useState<ActivityFee[]>(getActivitiesFeesResponse);
  const [selectedActivity, setSelectedActivity] = useState<Activity>();
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const [activitiesKeyValueList, setActivitiesKeyValueList] = useState<KeyValuePair[]>([]);

  useEffect(() => {
    const keyValueList: KeyValuePair[] = [ { value: FORM_SELECT_DEFAULT_OPTION_TEXT} ];
    getActivitiesResponse?.map((activity) => {
      keyValueList.push({ key: activity.id as string, value: activity.name });
    });
    setActivitiesKeyValueList(keyValueList);
  }, []);

  useEffect(() => {
    let activitiesFees: ActivityFee[] = getActivitiesFeesResponse;
    if (selectedActivity) {
      activitiesFees = getActivitiesFeesResponse.filter((activityFee) => activityFee.activityId === selectedActivity.id);
    }
    if (selectedYear) {
      activitiesFees = activitiesFees.filter((activityFee) => new Date(activityFee.startDate).getUTCFullYear() === selectedYear);
    }

    setActivitiesFees(activitiesFees);
  }, [selectedActivity, selectedYear]);

  const deleteActivityFee = async (activityFeeId: string) => {
    const succeeded = await makeDeletePeriodicProductFeeCall(activityFeeId);
    if (succeeded) {
      setActivitiesFees(activitiesFees.filter((activityFee) => activityFee.id !== activityFeeId))
    }
  }

  const getFilterDropdown = () => {
    return (
      <FormInput
        controlId="filter"
        label="Actividad"
        type="select"
        name="activity"
        value={selectedActivity?.id}
        onChangeEvent={(e: React.ChangeEvent<HTMLInputElement>) => { setSelectedActivity(getActivitiesResponse.find((activity => activity.id === e.target.value))) }}
        onBlurEvent={() => {}}
        touchedField={null}
        errorField={null}
        isDisabled={false}
        selectOptions={activitiesKeyValueList}
      />
    )
  }

  return (
    <Row>
      <h2>Aranceles de actividades</h2>
      <h3>Listado</h3>
      <Row md={6}>
        {getFilterDropdown()}
        <YearDropdown selectedYear={selectedYear} setSelectedYear={setSelectedYear}/>
      </Row>
      <Row>
        <PeriodicProductFeesList discriminator={ACTIVITY_FEE_DISCRIMINATOR} periodicProducts={activitiesFees ?? []} deleteFunction={deleteActivityFee}/>
      </Row>
    </Row>
  )
}
