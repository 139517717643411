import {Col, Row} from "react-bootstrap";
import React from "react";
import UserForm from "../../../components/forms/user-form";
import {useToastConfiguration} from "../../../App";

export default function AddUser() {
  const { toastConfiguration } = useToastConfiguration();

  return (
    <Row>
      <Col>
        <h3>Cargar usuario</h3>
        <UserForm toastConfiguration={toastConfiguration}  afterSubmitting={() => {}}/>
      </Col>
    </Row>
  );
}
