import { Toast } from "react-bootstrap";
import { TOAST_DEFAULT_SHOWN_TIME_MS } from "../constants";

export interface Props {
  variant: string;
  onClose: () => void;
  show: boolean;
  title: string;
  body: string;
}

export default function ToastMessage(props: Props) {
  return (
    <Toast onClose={() => props.onClose()} show={props.show} delay={TOAST_DEFAULT_SHOWN_TIME_MS} autohide bg={props.variant}>
      <Toast.Header>
        <strong className="me-auto">{props.title}</strong>
      </Toast.Header>
      <Toast.Body>{props.body}</Toast.Body>
    </Toast>
  )
}
