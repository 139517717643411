import {Outlet} from "react-router-dom";
import { useToastConfiguration } from "../../App";

function Members() {
  const { toastConfiguration } = useToastConfiguration();

  return (
    <Outlet context={{toastConfiguration}} />
  )
}

export default Members;
