import {Button, Col, Form, Row} from "react-bootstrap";
import FormInput from "./form-input";
import {useFormik} from "formik";
import * as Yup from "yup";
import {makePostSportsCall} from "../../services/sport-service";
import {KeyValuePair} from "../../models/key-value-pair";
import {makePostValuesListsCall} from "../../services/values-lists-service";
import {BUTTON_CREATE_NEW_LABEL, TOAST_SUCCESSFUL_OPERATION_TITLE} from "../../constants";
import {ToastConfiguration} from "../../models/toast-configuration";

export interface Props {
  valuesLists: KeyValuePair[];
  afterSubmitting?: () => void;
  toastConfiguration?: ToastConfiguration | null;
}

export default function ValueListForm(props: Props) {
  const formik = useFormik({
    initialValues: {
      lookupListId: '',
      value: ''
    },
    validationSchema: Yup.object({
      lookupListId: Yup.string().required('Es requerido'),
      value: Yup.string().required('Es requerido')
    }),
    onSubmit: async () => {
      props.toastConfiguration?.setShow(true);
      const response = await makePostValuesListsCall({
        lookupListId: formik.values.lookupListId,
        value: formik.values.value
      });
      if (response) {
        if (props.afterSubmitting) {
          props.toastConfiguration?.setOperationSuccessful(true);
          props.toastConfiguration?.setTitle(TOAST_SUCCESSFUL_OPERATION_TITLE);
          props.toastConfiguration?.setBody('El valor ha sido cargado exitosamente.');
          formik.resetForm();
          props.afterSubmitting();
        }
      }
    }
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row className="xs-3">
        <Col xs={4}>
          <FormInput
            controlId="formGridLookupListId"
            label="Lista de valores"
            type="select"
            name="lookupListId"
            value={formik.values.lookupListId}
            onChangeEvent={formik.handleChange}
            onBlurEvent={formik.handleBlur}
            touchedField={formik.touched.lookupListId}
            errorField={formik.errors.lookupListId}
            placeholder=""
            isDisabled={false}
            selectOptions={props.valuesLists}
          />
          <FormInput
            controlId="formGridValue"
            label="Valor"
            type="text"
            name="value"
            value={formik.values.value}
            onChangeEvent={formik.handleChange}
            onBlurEvent={formik.handleBlur}
            touchedField={formik.touched.value}
            errorField={formik.errors.value}
            placeholder="Nuevo valor"
            isDisabled={false}
          />
        </Col>
      </Row>
      <Button variant="primary" type="submit">{BUTTON_CREATE_NEW_LABEL}</Button>
    </Form>
  )
}
