import {AxiosResponse, HttpStatusCode} from "axios";
import axiosPrivate from "../models/axios/axios-private";
import {
  GET_ACTIVITIES_ENDPOINT,
  POST_ACTIVITIES_ENDPOINT
} from "../constants";
import {Activity} from "../models/activity";

export const makeGetActivitiesCall = async(): Promise<AxiosResponse<Activity[]> | null> => {
  const response = await axiosPrivate.get(GET_ACTIVITIES_ENDPOINT);
  if (response.status === HttpStatusCode.Ok) {
    return response;
  } else {
    console.log(
      `Error while trying to login activities: ${response.statusText}`
    );
    return null;
  }
};

export const makePostActivitiesCall = async(request: Activity): Promise<AxiosResponse | null> => {
  const response = await axiosPrivate.post(POST_ACTIVITIES_ENDPOINT, request);
  if (response.status === HttpStatusCode.Created) {
    return response;
  } else {
    console.log(
      `Error while trying to create activity: ${response.statusText}`
    );
    return null;
  }
};
