import {Button, Form, Modal} from "react-bootstrap";
import React from "react";

interface Props {
  titleText: string;
  body: JSX.Element;
  show: boolean;
  setShow: (value: boolean) => void;
  handleSubmit?: () => void;
}

export default function ConfirmationPopup(props: Props) {
  /*
  const handleSubmit = async(e: React.FormEvent) => {
    e.preventDefault();
    const result = await props.handleSubmit();
    if (result) {
      await props.operationCompleteCallback();
    }
  }
  */

  return (
    <Modal show={props.show} onHide={() => props.setShow(false)}>
      <Form onSubmit={props.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{props.titleText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.body}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            props.setShow(false);
          }}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit">Confirmar</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
