import {Button, Col, Form, Row} from "react-bootstrap";
import {useFormik} from "formik";
import {Operation} from "../../models/operation";
import FormInput from "./form-input";
import {
  BUTTON_CONFIRM_UPDATE_LABEL,
  DEFAULT_SHORT_ISO_DATE_FORMAT,
  MEMBERSHIP_FEE_DISCRIMINATOR,
  TOAST_SUCCESSFUL_OPERATION_TITLE
} from "../../constants";
import React from "react";
import {HttpStatusCode} from "axios";
import {ToastConfiguration} from "../../models/toast-configuration";
import {PeriodicProduct} from "../../models/periodic-product";
import { getDate } from "../../helpers";
import {makePutMembershipFeesCall} from "../../services/product-service";

interface Props {
  product: PeriodicProduct;
  discriminator: string;
  operation: Operation;
  toastConfiguration?: ToastConfiguration | null;
  afterSubmitting?: () => void;
}

export default function ProductForm(props: Props) {
  const formik = useFormik({
    initialValues: {
      id: props.product.id,
      name: props.product.name,
      shortName: props.product.shortName,
      discriminator: props.discriminator,
      description: props.product.description,
      price: props.product.price,
      startDate: props.product.startDate,
      endDate: props.product.endDate,
      dueDate: props.product.dueDate,
      comments: props.product.comments
    },
    validationSchema: null,
    onSubmit: async () => {
      props.toastConfiguration?.setShow(true);
      const product: PeriodicProduct = {
        id: props.product.id,
        name: formik.values.name,
        shortName: formik.values.shortName,
        discriminator: props.discriminator,
        description: formik.values.description,
        price: formik.values.price,
        startDate: formik.values.startDate,
        endDate: formik.values.endDate,
        dueDate: formik.values.dueDate,
        comments: formik.values.comments
      }
      const response = await makePutMembershipFeesCall(product);
      if (response?.status === HttpStatusCode.Ok) {
        props.toastConfiguration?.setOperationSuccessful(true);
        props.toastConfiguration?.setTitle(TOAST_SUCCESSFUL_OPERATION_TITLE);
        props.toastConfiguration?.setBody(props.discriminator === MEMBERSHIP_FEE_DISCRIMINATOR
          ? 'La cuota social ha sido editada exitosamente.'
          : 'El arancel ha sido editado exitosamente.'
        );
      }
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <h3>{props.discriminator === MEMBERSHIP_FEE_DISCRIMINATOR ? 'Cuota social' : 'Arancel'}</h3>
      <Row>
        <Col xs={5}>
          <FormInput
            controlId="name"
            label="Nombre"
            type="text"
            name="name"
            value={formik.values.name}
            onChangeEvent={formik.handleChange}
            onBlurEvent={formik.handleBlur}
            touchedField={formik.touched.name}
            errorField={formik.errors.name}
            placeholder=""
            isDisabled={props.operation === Operation.View}
          />
        </Col>

        <Col xs={4}>
          <FormInput
            controlId="shortName"
            label="Nombre corto"
            type="text"
            name="lastName"
            value={formik.values.shortName}
            onChangeEvent={formik.handleChange}
            onBlurEvent={formik.handleBlur}
            touchedField={formik.touched.shortName}
            errorField={formik.errors.shortName}
            placeholder=""
            isDisabled={props.operation === Operation.View}
          />
        </Col>

        <Col xs={3}>
          <FormInput
            controlId="price"
            label="Valor"
            type="number"
            name="price"
            value={formik.values.price}
            onChangeEvent={formik.handleChange}
            onBlurEvent={formik.handleBlur}
            touchedField={formik.touched.price}
            errorField={formik.errors.price}
            isDisabled={props.operation === Operation.View}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <FormInput
            controlId="description"
            label="Descripción"
            type="textarea"
            name="description"
            value={formik.values.description}
            onChangeEvent={formik.handleChange}
            onBlurEvent={formik.handleBlur}
            touchedField={formik.touched.description}
            errorField={formik.errors.description}
            isDisabled={props.operation === Operation.View}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={4}>
          <FormInput
            controlId="startDate"
            label="Fecha de inicio de vigencia"
            type="date"
            name="startDate"
            value={getDate(formik.values.startDate, DEFAULT_SHORT_ISO_DATE_FORMAT, true)?.toString()}
            onChangeEvent={formik.handleChange}
            onBlurEvent={formik.handleBlur}
            touchedField={formik.touched.startDate}
            errorField={formik.errors.startDate}
            placeholder={""}
            isDisabled={props.operation === Operation.View}
          />
        </Col>

        <Col xs={4}>
          <FormInput
            controlId="dueDate"
            label="Fecha de vencimiento"
            type="date"
            name="dueDate"
            value={getDate(formik.values.dueDate, DEFAULT_SHORT_ISO_DATE_FORMAT, false)?.toString()}
            onChangeEvent={formik.handleChange}
            onBlurEvent={formik.handleBlur}
            touchedField={formik.touched.dueDate}
            errorField={formik.errors.dueDate}
            placeholder={""}
            isDisabled={props.operation === Operation.View}
          />
        </Col>

        <Col xs={4}>
          <FormInput
            controlId="endDate"
            label="Fecha de fin de vigencia"
            type="date"
            name="endDate"
            value={getDate(formik.values.endDate, DEFAULT_SHORT_ISO_DATE_FORMAT, true)?.toString()}
            onChangeEvent={formik.handleChange}
            onBlurEvent={formik.handleBlur}
            touchedField={formik.touched.endDate}
            errorField={formik.errors.endDate}
            placeholder={""}
            isDisabled={props.operation === Operation.View}
          />
        </Col>

        <Col xs={12}>
          <FormInput
            controlId="comments"
            label="Comentarios"
            type="textarea"
            name="comments"
            value={formik.values.comments ?? ''}
            onChangeEvent={formik.handleChange}
            onBlurEvent={formik.handleBlur}
            touchedField={formik.touched.comments}
            errorField={formik.errors.comments}
            placeholder={""}
            isDisabled={props.operation === Operation.View}
          />
        </Col>
      </Row>

      <Row>
        <div className="d-flex justify-content-center">
          <Button variant="primary" type="submit">{BUTTON_CONFIRM_UPDATE_LABEL}</Button>
        </div>
      </Row>
    </Form>
  )
}
