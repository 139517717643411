import dateFormat, { i18n } from "dateformat";
import { Member } from "./models/member/member";
import {UserLocalStorage} from "./models/user-local-storage";
import {PeriodicProduct} from "./models/periodic-product";
import {ACTIVITY_FEE_DISCRIMINATOR, FORM_SELECT_ALL_OPTION_TEXT, FORM_SELECT_DEFAULT_OPTION_TEXT} from "./constants";
import {Activity} from "./models/activity";
import {KeyValuePair} from "./models/key-value-pair";
import {LookupListValue} from "./models/lookup-list-value";

// MEMBERS

export const getMemberFullName = (member: Member) => `${member.firstName} ${member.lastName}`;

// BOOLEANS

export const getBooleanString = (value: boolean) =>
  value
    ? 'Sí'
    : 'No';

// DATES

i18n.dayNames = [
  "Dom.",
  "Lun.",
  "Mar.",
  "Miér.",
  "Juev.",
  "Vier.",
  "Sáb.",
  "Domingo",
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
];

i18n.monthNames = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dic",
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

const months = [ "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre" ];

export const getDate = (date: Date | undefined, format: string, utc: boolean = false) => {
  if (!date) {
    return null;
  }

  return dateFormat(date, format, utc);
}

export const getLookupListValueTextById = (lookupListValuesList: KeyValuePair[], lookupListValueId: string): string =>
  lookupListValuesList.find(x => x.key === lookupListValueId)?.value || "";

// MEMBERSHIP FEES

export const createPeriodicProductsFeesByYear = (discriminator: string, year: number, price: number, activity?: Activity) => {
  const periodicProducts: PeriodicProduct[] = [];
  const firstNamePart = discriminator === ACTIVITY_FEE_DISCRIMINATOR
    ? `Arancel "${activity?.name}"`
    : 'Cuota social';

  for (let i = 0; i < 12; i++) {
    const month = i18n.monthNames[i + 12];
    const name = discriminator === ACTIVITY_FEE_DISCRIMINATOR
      ? `${firstNamePart} - ${month} ${year}`
      : `${firstNamePart} ${month.toLowerCase()} ${year}`
    periodicProducts.push({
      //key: `${month}-${year}`,
      name: name,
      shortName: `${month} ${year}`,
      price: price,
      discriminator: discriminator,
      startDate: new Date(year, i, 1),
      endDate: new Date(year, i+1, 0),
      dueDate: new Date(year, i, 10),
      description: name,
      comments: null
    })
  }
  return periodicProducts;
}

export const getProductRemainingAmount = (periodicProduct: PeriodicProduct): number => {
  if ((!periodicProduct.paymentDetails || periodicProduct.paymentDetails.length === 0) && periodicProduct.price > 0) return periodicProduct.price;

  let paid = 0.00;
  periodicProduct.paymentDetails?.forEach(pd => paid = Number(paid + pd.price));
  return Number(periodicProduct.price) - Number(paid);
}

const subtractDays = (date: Date, days: number): Date => {
  const result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
};

const addDays = (date: Date, days: number): Date => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const getPeriods = (): KeyValuePair[] => {
  const periods: KeyValuePair[] = [];
  const oldestMonth = subtractDays(new Date(), 365);
  const newestMonth = addDays(new Date(), 90);
  oldestMonth.setDate(1);
  newestMonth.setMonth(newestMonth.getMonth() + 1);
  newestMonth.setDate(0);

  while (oldestMonth <= newestMonth) {
    periods.push({
      key: `${oldestMonth.getFullYear().toString()}-${(oldestMonth.getMonth()+1) < 10 ? "0" : ""}${(oldestMonth.getMonth()+1).toString()}`,
      value: `${months.at(oldestMonth.getMonth())} ${oldestMonth.getFullYear()}`
    });
    oldestMonth.setMonth(oldestMonth.getMonth()+1);
  }

  return periods;
}

export interface YearMonth {
  year: number;
  month: number;
}

export const periodKeyToDate = (periodKey: string): YearMonth => {
  // 2023-04
  if (!periodKey) {
    const date = new Date();
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1
    }
  }

  const year = Number(periodKey.substring(0, 4));
  const month = Number(periodKey.substring(5));
  return {
    year, month
  }
}

export const getCurrentPeriod = () => {
  const date = new Date();
  return `${date.getFullYear()}-${(date.getMonth()+1) < 10 ? "0" : ""}${(date.getMonth()+1).toString()}`;
}

// PERMISSIONS

export const userHasPermission = (user: UserLocalStorage, moduleName: string, taskName: string) =>
  user.role.name === 'Admin' ||
  user.role.tasks.find((task) => taskName === task.name && moduleName === task.module.name) != null;

export const userHasPermissionToAccessModule = (user: UserLocalStorage, moduleName: string, taskNames: string[]) => {
  if (user.role.name === 'Admin') return true;

  for (const task of taskNames) {
    if (user.role.tasks.findIndex(x => x.name === task && moduleName === x.module.name)) {
      return true;
    }
  }
}


// DROPDOWNS VALUES

export const getDropdownValues = async (dataSource: Function | any, setFunction: Function, includeDefaultOption = true, includeAllOption = false) => {
  const results = typeof(dataSource) === "function" ? (await dataSource())?.data : dataSource;

  const keyValueList: KeyValuePair[] = [];
  if (includeDefaultOption || includeAllOption) {
    const firstOption = includeDefaultOption ? FORM_SELECT_DEFAULT_OPTION_TEXT : FORM_SELECT_ALL_OPTION_TEXT;
    keyValueList.push({ key: "", value: firstOption });
  }

  results.map((item: { id: string, name: string }) => keyValueList.push({key: item.id, value: item.name}));

  setFunction(keyValueList);
};

export const getDropdownValuesV2 = async (functionToCall: Function, setFunction: Function, includeDefaultOption = true, includeAllOption = false) => {
  const results = (await functionToCall())?.data[0].lookupListValues;

  const keyValueList: KeyValuePair[] = [];
  if (includeDefaultOption || includeAllOption) {
    const firstOption = includeDefaultOption ? FORM_SELECT_DEFAULT_OPTION_TEXT : FORM_SELECT_ALL_OPTION_TEXT;
    keyValueList.push({ key: "", value: firstOption });
  }

  results.map((item: LookupListValue) => keyValueList.push({key: item.id, value: item.value}));

  setFunction(keyValueList);
};

export const verifyLoggedInConditionsAreMet = (): boolean => {
  const user = localStorage.getItem('user');
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refreshToken');
  return user !== null && token !== null && refreshToken !== null;
}
