import {Col, Row} from "react-bootstrap";
import MemberForm from "../../components/forms/member-form";
import {Operation} from "../../models/operation";
import {useToastConfiguration} from "../../App";

function AddMember() {
  const { toastConfiguration } = useToastConfiguration();

  return (
    <>
      <Row>
        <Col md={{ span: 9, offset: 2 }}>
          <h1>Socios</h1>
          <h2>Nuevo socio</h2>
          <p>Complete los datos del nuevo socio.</p>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 9, offset: 2 }}>
          <MemberForm operation={Operation.Add} toastConfiguration={toastConfiguration} />
        </Col>
      </Row>
    </>
  )
}

export default AddMember;
