import {Col, Row} from "react-bootstrap";
import {Operation} from "../../models/operation";
import ActivityForm from "../../components/forms/activity-form";
import {useToastConfiguration} from "../../App";

export default function AddActivity() {
  const { toastConfiguration } = useToastConfiguration();

  return (
    <Row>
      <Col md={{ span: 8, offset: 2 }}>
        <h1>Actividades</h1>
        <h2>Nueva actividad</h2>
        <p>Complete los datos de la nueva actividad.</p>
        <ActivityForm operation={Operation.Add} afterSubmitting={() => {}} toastConfiguration={toastConfiguration}/>
      </Col>
    </Row>
  )
}
