import "./login.css";
import {Button, Col, Form, Row, Image} from "react-bootstrap";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useAuth} from "../hooks/use-auth";
import {useLocation, useNavigate } from 'react-router-dom';
import {HOME_PAGE_ROUTE} from "../constants";
import {useEffect} from "react";
import {useToastConfiguration} from "../App";
import {router} from "../router";

function Login() {
  const { toastConfiguration } = useToastConfiguration();
  const navigate = useNavigate();
  const location = useLocation();
  const { login, user, isLoggedIn } = useAuth();
  const userInitialValues = {
    username: '',
    password: ''
  };

  useEffect(() => {
    if (isLoggedIn()) {
      const from = location.state?.from;
      navigate(from || HOME_PAGE_ROUTE);
    }
  }, [user])

  const formik = useFormik({
    initialValues: userInitialValues,
    validationSchema: Yup.object(),
    onSubmit: async () => {
      const loginResult = await login({ username: formik.values.username, password: formik.values.password });
      if (!loginResult) {
        toastConfiguration?.setShow(true);
        toastConfiguration?.setBody('Las credenciales ingresadas son incorrectas.');
      }
    }
  });

  const getForm = () => {
    return (
      <Form onSubmit={formik.handleSubmit}>
        <Row className="justify-content-center">
          <Col className="col-3">
            <h1 className="align text-center">C.A. Pacífico</h1>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="d-flex col-3 justify-content-center">
            <Image src={`${router.basename}pacifico.png`} />
          </Col>
        </Row>
        <Row>
          <Col>
            <h2 id="internal-system-title" className="align text-center">Sistema interno</h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={10} md={6}>
            <h2>Iniciar sesión</h2>
            <Form.Group controlId="formControlUsername">
              <Form.Label>Nombre de usuario</Form.Label>
              <Form.Control
                type="text"
                name="username"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Ingrese nombre de usuario"
              />
            </Form.Group>

            <Form.Group controlId="formControlPassword">
              <Form.Label>Contraseña</Form.Label>
              <Form.Control
                type="password"
                name="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Ingrese su contraseña"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="row justify-content-center">
          <Col className="col-1">
            <Button id="btn-login" variant="primary" type="submit">Ingresar</Button>
          </Col>
        </Row>
      </Form>
    )
  }

  return (
    <div>
      <Row>
        {getForm()}
      </Row>
    </div>
  );
}

export default Login;
