import React from "react";
import {createBrowserRouter, createRoutesFromElements, Route} from "react-router-dom";
import App from "./App";
import Home, {homeLoader} from "./pages/home";
import Login from "./pages/login";
import Sports, {listSportsLoader} from "./pages/sports";
import Members from "./pages/members/members";
import ListMembers, {listMembersLoader} from "./pages/members/list-members";
import AddMember from "./pages/members/add-member";
import EditMember, {editMemberLoader} from "./pages/members/edit-member";
import ViewMember, {viewMemberLoader} from "./pages/members/view-member";
import ProtectedRoute from "./components/protected-route";
import Activities from "./pages/activities/activities";
import ListActivities, { listActivitiesLoader } from "./pages/activities/list-activities";
import AddActivity from "./pages/activities/add-activity";
import Settings from "./pages/settings/settings";
import Values, {valuesListsLoader} from "./pages/settings/values";
import MembershipFees from "./pages/membership-fees/membership-fees";
import AddMembershipFee from "./pages/membership-fees/add-membership-fee";
import ListMembershipFees, { listMembershipFeesLoader } from "./pages/membership-fees/list-membership-fees";
import AuthorizedRoute from "./components/authorized-route";
import {
  ACTIVITIES_MODULE_NAME,
  ADD_ACTIVITY_FEE_TASK_NAME,
  ADD_ACTIVITY_TASK_NAME,
  ADD_MEMBER_TASK_NAME,
  EDIT_MEMBER_TASK_NAME,
  LIST_ACTIVITY_TASK_NAME,
  LIST_MEMBER_TASK_NAME,
  MEMBERSHIP_FEES_MODULE_NAME,
  MEMBERS_MODULE_NAME,
  SETTINGS_MODULE_NAME,
  SPORTS_MODULE_NAME,
  VIEW_CREATE_SPORT_TASK_NAME,
  VIEW_MEMBER_TASK_NAME,
  VIEW_VALUE_LIST_TASK_NAME,
  ADD_MEMBERSHIP_FEES_TASK_NAME,
  LIST_MEMBERSHIP_FEES_TASK_NAME,
  EDIT_MEMBERSHIP_FEES_TASK_NAME,
  REPORTS_MODULE_NAME,
  USERS_MODULE_NAME,
  ADD_USERS_TASK_NAME,
  ROLES_MODULE_NAME,
  ADD_ROLES_TASK_NAME,
  LIST_ROLES_TASK_NAME,
  LIST_USERS_TASK_NAME,
  EDIT_ACTIVITY_FEES_TASK_NAME,
  VIEW_ACTIVITY_TASK_NAME,
  PAYMENTS_MODULE_NAME,
  LIST_PAYMENTS_TASK_NAME,
  GENERATE_REPORTS_OWED_MEMBERSHIP_FEES_BASKET,
  GENERATE_REPORTS_OWED_MEMBERSHIP_FEES_FOOTBALL,
  GENERATE_REPORTS_OWED_MEMBERSHIP_FEES_HOCKEY,
  GENERATE_REPORTS_OWED_MEMBERSHIP_FEES_TENNIS,
  GENERATE_REPORTS_MEMBER_STATUS_BY_MONTH_BASKET,
  GENERATE_REPORTS_MEMBER_STATUS_BY_MONTH_FOOTBALL,
  GENERATE_REPORTS_MEMBER_STATUS_BY_MONTH_HOCKEY,
  GENERATE_REPORTS_MEMBER_STATUS_BY_MONTH_TENNIS,
  GENERATE_REPORTS_MEMBERS_BASKET,
  GENERATE_REPORTS_MEMBERS_FOOTBALL,
  GENERATE_REPORTS_MEMBERS_HOCKEY,
  GENERATE_REPORTS_MEMBERS_TENNIS
} from "./constants";
import AddActivityFee from "./pages/fees/add-activity-fees";
import Reports from "./pages/admin/reports";
import Admin from "./pages/admin/admin";
import EditMembershipFee, {editMembershipFeeLoader} from "./pages/membership-fees/edit-membership-fee";
import UserConfig from "./pages/user-config";
import AddRole, {getModulesLoader} from "./pages/settings/roles/add-role";
import AddUser from "./pages/settings/users/add-user";
import Users from "./pages/settings/users/users";
import {getRolesLoader} from "./components/forms/user-form";
import Roles from "./pages/settings/roles/roles";
import MemberStatus from "./pages/members/member-status";
import ListRoles from "./pages/settings/roles/list-roles";
import ListUsers, {listUsersLoader} from "./pages/settings/users/list-users";
import ListActivitiesFees, {listActivitiesFeesLoader} from "./pages/fees/list-activities-fees";
import EditActivityFee, { editActivityFeeLoader } from "./pages/fees/edit-activities-fees";
import ViewActivity, {viewActivityLoader} from "./pages/activities/view-activity";
import Payments from "./pages/payments/payments";
import ListPayments, {listPaymentsLoader} from "./pages/payments/list-payments";

const reportsTasks: string[] = [
  GENERATE_REPORTS_OWED_MEMBERSHIP_FEES_BASKET,
  GENERATE_REPORTS_OWED_MEMBERSHIP_FEES_FOOTBALL,
  GENERATE_REPORTS_OWED_MEMBERSHIP_FEES_HOCKEY,
  GENERATE_REPORTS_OWED_MEMBERSHIP_FEES_TENNIS,
  GENERATE_REPORTS_MEMBER_STATUS_BY_MONTH_BASKET,
  GENERATE_REPORTS_MEMBER_STATUS_BY_MONTH_FOOTBALL,
  GENERATE_REPORTS_MEMBER_STATUS_BY_MONTH_HOCKEY,
  GENERATE_REPORTS_MEMBER_STATUS_BY_MONTH_TENNIS,
  GENERATE_REPORTS_MEMBERS_BASKET,
  GENERATE_REPORTS_MEMBERS_FOOTBALL,
  GENERATE_REPORTS_MEMBERS_HOCKEY,
  GENERATE_REPORTS_MEMBERS_TENNIS
]

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<ProtectedRoute><App /></ProtectedRoute>} >
      <Route index element={<Home />} loader={homeLoader} />
      <Route path="login" element={<Login />} />
      <Route path="sports" element={<AuthorizedRoute moduleName={SPORTS_MODULE_NAME} taskName={VIEW_CREATE_SPORT_TASK_NAME}><Sports /></AuthorizedRoute>} loader={listSportsLoader} />
      <Route path="members" element={<Members />}>
        <Route path="list" element={<AuthorizedRoute moduleName={MEMBERS_MODULE_NAME} taskName={LIST_MEMBER_TASK_NAME}><ListMembers /></AuthorizedRoute>} loader={listMembersLoader} />
        <Route path="add" element={<AuthorizedRoute moduleName={MEMBERS_MODULE_NAME} taskName={ADD_MEMBER_TASK_NAME}><AddMember /></AuthorizedRoute>} />
        <Route path="edit/:memberId" element={<AuthorizedRoute moduleName={MEMBERS_MODULE_NAME} taskName={EDIT_MEMBER_TASK_NAME}><EditMember /></AuthorizedRoute>} loader={editMemberLoader} />
        <Route path="view/:memberId" element={<AuthorizedRoute moduleName={MEMBERS_MODULE_NAME} taskName={VIEW_MEMBER_TASK_NAME}><ViewMember /></AuthorizedRoute>} loader={viewMemberLoader} />
        <Route path="status/:memberId" element={<AuthorizedRoute moduleName={MEMBERS_MODULE_NAME} taskName={VIEW_MEMBER_TASK_NAME}><MemberStatus /></AuthorizedRoute>} loader={viewMemberLoader} />
      </Route>
      <Route path="activities" element={<Activities />}>
        <Route path="list" element={<AuthorizedRoute moduleName={ACTIVITIES_MODULE_NAME} taskName={LIST_ACTIVITY_TASK_NAME}><ListActivities /></AuthorizedRoute>} loader={listActivitiesLoader} />
        <Route path="add" element={<AuthorizedRoute moduleName={ACTIVITIES_MODULE_NAME} taskName={ADD_ACTIVITY_TASK_NAME}><AddActivity /></AuthorizedRoute>} loader={listSportsLoader} />
        <Route path="edit/:activityId" element={<></>} />
        <Route path="view/:activityId" element={<AuthorizedRoute moduleName={ACTIVITIES_MODULE_NAME} taskName={VIEW_ACTIVITY_TASK_NAME}><ViewActivity /></AuthorizedRoute>} loader={viewActivityLoader} />
        <Route path="fees" element={<Activities />}>
          <Route path="add" element={<AuthorizedRoute moduleName={ACTIVITIES_MODULE_NAME} taskName={ADD_ACTIVITY_FEE_TASK_NAME}><AddActivityFee /></AuthorizedRoute>} />
          <Route path="list" element={<AuthorizedRoute moduleName={ACTIVITIES_MODULE_NAME} taskName={LIST_ACTIVITY_TASK_NAME}><ListActivitiesFees /></AuthorizedRoute>} loader={listActivitiesFeesLoader} />
          <Route path="edit/:activityFeeId" element={<AuthorizedRoute moduleName={ACTIVITIES_MODULE_NAME} taskName={EDIT_ACTIVITY_FEES_TASK_NAME}><EditActivityFee /></AuthorizedRoute>} loader={editActivityFeeLoader} />
        </Route>
      </Route>
      <Route path="membership-fees" element={<MembershipFees />}>
        <Route path="list" element={<AuthorizedRoute moduleName={MEMBERSHIP_FEES_MODULE_NAME} taskName={LIST_MEMBERSHIP_FEES_TASK_NAME}><ListMembershipFees /></AuthorizedRoute>} loader={listMembershipFeesLoader} />
        <Route path="add" element={<AuthorizedRoute moduleName={MEMBERSHIP_FEES_MODULE_NAME} taskName={ADD_MEMBERSHIP_FEES_TASK_NAME}><AddMembershipFee /></AuthorizedRoute>} loader={listSportsLoader} />
        <Route path="edit/:membershipFeeId" element={<AuthorizedRoute moduleName={MEMBERSHIP_FEES_MODULE_NAME} taskName={EDIT_MEMBERSHIP_FEES_TASK_NAME}><EditMembershipFee /></AuthorizedRoute>} loader={editMembershipFeeLoader} />
      </Route>
      <Route path="admin" element={<Admin />}>
        <Route path="reports" element={<AuthorizedRoute moduleName={REPORTS_MODULE_NAME} tasksNames={reportsTasks}><Reports /></AuthorizedRoute>} />
      </Route>
      <Route path="settings" element={<Settings />}>
        <Route path="values" element={<AuthorizedRoute moduleName={SETTINGS_MODULE_NAME} taskName={VIEW_VALUE_LIST_TASK_NAME}><Values /></AuthorizedRoute>} loader={valuesListsLoader} />
        <Route path="user-config" element={<UserConfig />} />
        <Route path="users" element={<Users />}>
          <Route path="add" element={<AuthorizedRoute moduleName={USERS_MODULE_NAME} taskName={ADD_USERS_TASK_NAME}><AddUser /></AuthorizedRoute>} loader={getRolesLoader} />
          <Route path="list" element={<AuthorizedRoute moduleName={USERS_MODULE_NAME} taskName={LIST_USERS_TASK_NAME}><ListUsers /></AuthorizedRoute>} loader={listUsersLoader} />
        </Route>
        <Route path="roles" element={<Roles />}>
          <Route path="add" element={<AuthorizedRoute moduleName={ROLES_MODULE_NAME} taskName={ADD_ROLES_TASK_NAME}><AddRole /></AuthorizedRoute>} loader={getModulesLoader} />
          <Route path="list" element={<AuthorizedRoute moduleName={ROLES_MODULE_NAME} taskName={LIST_ROLES_TASK_NAME}><ListRoles /></AuthorizedRoute>} loader={getRolesLoader} />
        </Route>
      </Route>
      <Route path="payments" element={<Payments />}>
        <Route path="list" element={<AuthorizedRoute moduleName={PAYMENTS_MODULE_NAME} taskName={LIST_PAYMENTS_TASK_NAME}><ListPayments /></AuthorizedRoute>} loader={listPaymentsLoader} />
      </Route>
    </Route>
  ), {
    basename: process.env.NODE_ENV === 'production' ? '' : ''
  }
);
