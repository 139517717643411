import {Button, Col, Row, Table} from "react-bootstrap";
import React, {useState} from "react";
import {Link, useLoaderData} from "react-router-dom";
import {makeGetActivitiesCall} from "../../services/activity-service";
import { Activity } from "../../models/activity";
import CurrencyData from "../../components/currency-data";

export const listActivitiesLoader = async(): Promise<Activity[]> => {
  return (await makeGetActivitiesCall())?.data as Activity[];
};

export default function ListActivities() {
  const getActivitiesResponse = useLoaderData() as Activity[];
  const [activitiesResponse] = useState<Activity[]>(getActivitiesResponse);

  // Table
  const getTable = (): JSX.Element => {
    return (
      <Table responsive striped bordered hover>
        <thead>
        <tr>
          <th>Nombre</th>
          <th>Deporte</th>
          <th>Valor mensual</th>
          <th>Opciones</th>
        </tr>
        </thead>
        {getTableRows()}
      </Table>
    )
  }

  const getTableRows = (): JSX.Element => {
    if (!activitiesResponse) {
      return <></>
    }

    const activitiesRows = activitiesResponse.map((activity: Activity) => (
      <tr key={activity.id}>
        <td>{activity.name}</td>
        <td>{activity.sport?.name}</td>
        <td><CurrencyData value={activity.monthlyPrice} /></td>
        <td>
          <div>
            <Button type="button" variant="primary">
              <Link to={`../view/${activity.id}`} className="link-button-primary">Ver detalles</Link>
            </Button>
          </div>
        </td>
      </tr>
    ));

    return (
      <tbody>{activitiesRows}</tbody>
    )
  }

  return (
    <Row id="activity-list">
      <h3>Listado</h3>
      <Col>
        {getTable()}
      </Col>
    </Row>
  )
}
