import {Accordion, Button, Col, Form, Row, Stack, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {makeGetActivitiesFeesByMemberIdCall} from "../../services/product-service";
import { Member } from "../../models/member/member";
import {Activity} from "../../models/activity";
import {getBooleanString, getDate, getMemberFullName} from "../../helpers";
import {ProductPaymentNew} from "../../models/membership-fee/product-payment";
import {ActivityFee} from "../../models/activity-fee";
import AddMemberActivityPopup from "../add-member-activity-popup";
import {ToastConfiguration} from "../../models/toast-configuration";
import {ActivityProductPayment} from "../../models/web/activities-fees-by-member-response";
import PaymentPopup from "../payment-popup";
import {
  ACTIVITY_FEE_DISCRIMINATOR,
  DEFAULT_SHORT_DATE_FORMAT,
  DEFAULT_SHORT_DATE_TIME_FORMAT,
  DEFAULT_SHORT_ISO_DATE_FORMAT,
  TOAST_SUCCESSFUL_OPERATION_TITLE
} from "../../constants";
import {PartialPayment} from "../../models/partial-payment";
import CurrencyData from "../currency-data";
import {
  makeGetMemberByIdCall, makePatchMemberActivityCall
} from "../../services/member-service";
import ConfirmationPopup from "../confirmation-popup";
import { MemberActivityDto } from "../../models/dtos/member-activity-dto";
import FormInput from "../forms/form-input";
import {useFormik} from "formik";

export interface Props {
  member: Member;
  toastConfiguration?: ToastConfiguration | null;
}

export default function MemberActivities(props: Props) {
  const [memberActivities, setMemberActivities] = useState<Activity[]>(props.member.activities);
  const [activitiesFeesYears, setActivitiesFeesYears] = useState<string[]>([]);
  const [activitiesFeesPayments, setActivitiesFeesPayments] = useState(new Map<string, ActivityProductPayment[]>());
  const [activitiesFeesToPay, setActivitiesFeesToPay] = useState<ActivityFee[]>([]);
  const [paymentsToMake, setPaymentsToMake] = useState<ProductPaymentNew[]>([]);
  const [showAddMemberActivityPopup, setShowAddMemberActivityPopup] = useState(false);
  const [showPaymentConfirmationPopup, setShowPaymentConfirmationPopup] = useState(false);
  const [partialPayments, setPartialPayments] = useState<PartialPayment[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [removeActivityConfirmationPopupBody, setRemoveActivityConfirmationPopupBody] = useState<JSX.Element>(<></>);
  const [showRemoveActivityConfirmationPopup, setShowRemoveActivityConfirmationPopup] = useState(false);
  const [activityToRemove, setActivityToRemove] = useState<Activity>();

  const formik = useFormik({
    initialValues: {
      endDate: new Date(),
      comments: undefined,
    },
    onSubmit: async () => {
      if (!activityToRemove?.id) return Promise.resolve(true);

      const memberActivityDto: MemberActivityDto = {
        activityId: activityToRemove.id,
        endDate: formik.values.endDate,
        comments: formik.values.comments ? formik.values.comments : `Socio dado de baja de la actividad el ${getDate(new Date(), DEFAULT_SHORT_DATE_FORMAT, false)}`
      };

      const response = await makePatchMemberActivityCall(props.member.id!, memberActivityDto);
      if (response.isSuccessful) {
        props.toastConfiguration?.setTitle(TOAST_SUCCESSFUL_OPERATION_TITLE);
        props.toastConfiguration?.setBody("El socio ha sido dado de baja de la actividad.");
        props.toastConfiguration?.setOperationSuccessful(true);
        const memberActivities = ((await makeGetMemberByIdCall(props.member.id!))?.data as Member).activities;
        setMemberActivities(memberActivities);
        formik.resetForm();
      }

      setShowRemoveActivityConfirmationPopup(false);
      props.toastConfiguration?.setShow(true);
    }
  });

  const getActivitiesFeesByMemberId = async () => {
    const response = (await makeGetActivitiesFeesByMemberIdCall(props.member.id!))?.data;
    if (!response) {
      return;
    }
    const keys = Object.keys(response.activityFeesPayments);
    const entries = Object.entries(response.activityFeesPayments);
    const map = new Map(entries);

    setActivitiesFeesYears(keys.reverse());
    setActivitiesFeesPayments(map);
  }

  useEffect(() => {
    getActivitiesFeesByMemberId().then();
  }, []);

  const getMemberActivitiesList = (current: boolean = true) => {
    let key = -1;
    const accordionItems = memberActivities.map((activity: Activity) => {
      if (((current && !activity.memberActivity?.endDate) || (!current && activity.memberActivity?.endDate)) && !activity.isInsurance) {
        key++;
        return (
          <Accordion.Item key={activity.id} eventKey={activity.id!}>
            <Accordion.Header>{activity.name}</Accordion.Header>
            <Accordion.Body>
              <Row>
                {getActivityDetails(activity)}
              </Row>
              <Row>
                {getActivitiesFeesYearsList(activity.id!)}
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        )
      }
      return null;
    });

    if (key < 0) {
      return (<p>No se registra ninguna actividad.</p>)
    }

    return (
      <Accordion defaultActiveKey="0" flush>
        {accordionItems}
      </Accordion>
    )
  }

  const generateConfirmationPopupBody = (activity: Activity) => {
    return (
      <>
        <p>{`¿Seguro que querés dar de baja de la actividad "${activity.name}" al socio ${getMemberFullName(props.member)}?`}</p>
        <FormInput controlId="endDate" label="Fecha de fin" type="date" name="endDate" value={getDate(formik.values.endDate, DEFAULT_SHORT_ISO_DATE_FORMAT, false)!} onChangeEvent={formik.handleChange} onBlurEvent={formik.handleBlur} />
        <FormInput controlId="comments" label="Comentarios" type="textarea" name="comments" value={formik.values.comments} onChangeEvent={formik.handleChange} onBlurEvent={formik.handleBlur} />
      </>
    )
  }

  const handleRemoveMemberActivityButtonClick = (activity: Activity) => {
    setRemoveActivityConfirmationPopupBody(generateConfirmationPopupBody(activity));
    setShowRemoveActivityConfirmationPopup(true);
    setActivityToRemove(activity);
  }

  const getActivityDetails = (activity: Activity) => {
    return (
      <Stack direction="horizontal" gap={3}>
        <div key={`${activity.id}-details`} className={`height-100 ${activity.memberActivity?.endDate ? "w-50" : ""}`}>
          <h4 key={`${activity.id}-detailsHeader`}>Detalles de la actividad</h4>
          <ul>
            <li>Deporte: {activity.sport?.name}</li>
            <li>Valor mensual: <CurrencyData value={activity.monthlyPrice}/></li>
            <li>Horarios: {activity.scheduleDetails}</li>
            <li>Ubicación: {activity.location}</li>
            <li>Profesores o responsables: {activity.responsiblePersonsFullNames}</li>
            <li>Requiere seguro: {activity.isInsuranceRequired ? 'Sí' : 'No'}</li>
          </ul>
        </div>

        <div className={`height-100 ${activity.memberActivity?.endDate ? "w-50" : "ms-auto"}`}>
          <h4>Detalles del socio practicando la actividad</h4>
          <ul>
            <li>Fecha de inicio: {getDate(activity.memberActivity?.startDate, DEFAULT_SHORT_DATE_FORMAT)}</li>
            <li>Fecha de fin: {getDate(activity.memberActivity?.endDate, DEFAULT_SHORT_DATE_FORMAT)}</li>
            <li>Comentarios: {activity.memberActivity?.comments}</li>
            <li>Fecha de carga en sistema: {getDate(activity.memberActivity?.createdAt, DEFAULT_SHORT_DATE_FORMAT)}</li>
          </ul>
        </div>

        {
          !activity.memberActivity?.endDate
            ? (
              <>
                <div className="vr" />
                <div className="height-100">
                  <Button variant="danger" onClick={() => handleRemoveMemberActivityButtonClick(activity)}>Dar de baja</Button>
                </div>
              </>
            )
            : null
        }
      </Stack>
    )
  }

  const getTable = (year: string, activityId: string) => {
    return (
      <Table striped bordered hover>
        <thead>
        <tr>
          <th>Descripción</th>
          <th>Valor</th>
          <th>Fecha de pago</th>
          <th>Método de pago</th>
          <th>Importe abonado</th>
          <th>Aplicable</th>
          <th>Pagar</th>
        </tr>
        </thead>
        {getTableRows(year, activityId)}
      </Table>
    )
  }

  const getTableRows = (year: string, activityId: string): JSX.Element => {
    if (!activitiesFeesPayments) {
      return <></>
    }

    const activityPaymentsByActivityId = activitiesFeesPayments.get(year)?.find((activity: ActivityProductPayment) => activity.activityId === activityId)?.productPayments;
    const activitiesFeesRows = activityPaymentsByActivityId?.map((activityFeePayment: ProductPaymentNew) => (
      <tr key={activityFeePayment.product.id}>
        <td>{activityFeePayment.product.shortName}</td>
        <td><CurrencyData value={activityFeePayment.product.price} /></td>
        <td>
          {
            (activityFeePayment.paymentDetails.length === 0)
              ? <></>
              : (activityFeePayment.paymentDetails?.length === 1)
                ? getDate(activityFeePayment.paymentDetails?.at(0)?.payment?.date, DEFAULT_SHORT_DATE_TIME_FORMAT)
                : activityFeePayment.paymentDetails?.map((x) => <p>{ getDate(x.payment?.date, DEFAULT_SHORT_DATE_TIME_FORMAT) }</p>)
          }
        </td>
        <td>
          {
            (activityFeePayment.paymentDetails.length === 0)
              ? <></>
              : (activityFeePayment.paymentDetails?.length === 1)
                ? activityFeePayment.paymentDetails?.at(0)?.payment.methodLlv?.value
                : activityFeePayment.paymentDetails?.map((x) => <p>{ x.payment.methodLlv?.value }</p>)
          }
        </td>
        <td>
          {
            (activityFeePayment.paymentDetails.length === 0)
              ? <></>
              : (activityFeePayment.paymentDetails?.length === 1)
                ? <CurrencyData value={activityFeePayment.paymentDetails?.at(0)?.price ?? 0} />
                : activityFeePayment.paymentDetails?.map((x) => <CurrencyData value={x.price} />)
          }
        </td>
        <td>{getBooleanString(activityFeePayment.isApplicable)}</td>
        <td className="text-center m-auto">{getCheckbox(activityFeePayment)}</td>
      </tr>
    ));

    return (
      <tbody>{activitiesFeesRows}</tbody>
    )
  }

  const handleCheckboxChange = (selectedActivityFee: ProductPaymentNew) => {
    const selectedActivityFeeProduct = selectedActivityFee.product as ActivityFee;
    const index = activitiesFeesToPay.findIndex((af) => af.id === selectedActivityFeeProduct.id);
    const activity = props.member.activities.find((activity) => activity.id === selectedActivityFeeProduct.activityId);
    let insuranceProductPayment: ProductPaymentNew | null = null;
    if (activity && activity.isInsuranceRequired) {
      const insuranceActivityId = props.member.activities.find((insuranceActivity) => insuranceActivity.isInsurance)?.id;
      activitiesFeesPayments.get(new Date(selectedActivityFeeProduct.startDate).getUTCFullYear().toString())?.find((activityProductPayment) => {
        return activityProductPayment.activityId === insuranceActivityId && activityProductPayment.productPayments.find((productPayment) => {
          if (productPayment.product.startDate === selectedActivityFeeProduct.startDate && productPayment.product.endDate === selectedActivityFeeProduct.endDate) {
            return insuranceProductPayment = productPayment;
          }
          return false;
        })
      })
    }

    if (index < 0) {
      setActivitiesFeesToPay([...activitiesFeesToPay, selectedActivityFeeProduct]);
      setPaymentsToMake([...paymentsToMake, selectedActivityFee]);
      if (insuranceProductPayment) {
        insuranceProductPayment = insuranceProductPayment as ProductPaymentNew;
        if (insuranceProductPayment.remainingPriceToPay > 0) {
          setActivitiesFeesToPay([...activitiesFeesToPay, selectedActivityFeeProduct, insuranceProductPayment.product as ActivityFee]);
          setPaymentsToMake([...paymentsToMake, selectedActivityFee, insuranceProductPayment]);
        }
      }
    }
    else {
      setActivitiesFeesToPay(activitiesFeesToPay.filter((activityFee) => activityFee.id !== activityFee.id));
      setPaymentsToMake(paymentsToMake.filter((pp) => pp.product.id !== selectedActivityFee.product.id));
      if (insuranceProductPayment) {
        setActivitiesFeesToPay(activitiesFeesToPay.filter((activityFee) => activityFee.id !== activityFee.id && activityFee.id !== insuranceProductPayment?.product.id));
        setPaymentsToMake(paymentsToMake.filter((pp) => pp.product.id !== selectedActivityFee.product.id && pp.product.id !== insuranceProductPayment?.product.id));
      }
    }
  }

  const getCheckbox = (activityFeePayment: ProductPaymentNew) => {
    if (activityFeePayment.isApplicable && activityFeePayment.remainingPriceToPay > 0) {
      return (
        <Form.Check key={activityFeePayment.product.id} label="" disabled={partialPayments.some(pp => !pp.isRemainingPart)} checked={ activitiesFeesToPay.findIndex(mf => mf.id === activityFeePayment.product.id) >= 0 } onChange={() => handleCheckboxChange(activityFeePayment)} />
      )
    }
    return <></>
  }

  const getActivitiesFeesYearsList = (activityId: string) => {
    let key = -1;
    const accordionItems = activitiesFeesYears.map((year: string) => {
      key++;
      return (
        <Accordion.Item key={key.toString()} eventKey={key.toString()}>
          <Accordion.Header>{year}</Accordion.Header>
          <Accordion.Body>{getTable(year, activityId)}</Accordion.Body>
        </Accordion.Item>
      )
    });

    return (
      <>
        <h4>Pagos</h4>
        <Accordion defaultActiveKey="0" flush>
          {accordionItems}
        </Accordion>
      </>
    )
  }

  const operationCompleteCallback = async () => {
    const memberActivities = ((await makeGetMemberByIdCall(props.member.id!))?.data as Member).activities;
    setMemberActivities(memberActivities);
    await getActivitiesFeesByMemberId();
    setPartialPayments([]);
    setActivitiesFeesToPay([]);
    setPaymentsToMake([]);
  }

  const getMembershipFeesToPayTable = () => {
    return (
      <Table striped bordered hover>
        <thead>
        <tr>
          <th>Descripción</th>
          <th>Valor</th>
          <th>Valor actualizado</th>
          <th>Pago parcial</th>
        </tr>
        </thead>
        {getActivitiesFeesToPayTableRows()}
      </Table>
    )
  }

  const getActivitiesFeesToPayTableRows = () => {
    const insurance = props.member.activities.find((activity) => activity.isInsurance);

    const activityFeesRows = paymentsToMake.map((productPayment: ProductPaymentNew) => {
      const isLastInsurance = productPayment.product === activitiesFeesToPay.at(activitiesFeesToPay.length - 1) && (productPayment.product as ActivityFee).activityId === insurance?.id;
      const isLastActivity = productPayment.product === activitiesFeesToPay.at(activitiesFeesToPay.length - 2) && (productPayment.product as ActivityFee).activityId !== insurance?.id;
      const isInsuranceActivity = (productPayment.product as ActivityFee).activityId === insurance?.id;
      const amountToPay = productPayment.remainingPriceToPay;
      const isInPartialPaymentsList = partialPayments.findIndex(pp => pp.productId === productPayment.product.id && !pp.isRemainingPart) >= 0;

      return (
        <tr key={productPayment.product.id}>
          <td>{productPayment.product.name}</td>
          <td><CurrencyData value={productPayment.product.price} /></td>
          <td>
            {
              isInPartialPaymentsList && (isLastInsurance || isLastActivity)
                ? getPartialPriceInput(productPayment.product.id!, amountToPay)
                : <div>
                  <CurrencyData value={amountToPay} />
                  {`${productPayment.remainingPriceToPay !== productPayment.totalPriceToPay ? ' (pendiente)' : ''}`}
                </div>
            }
          </td>
          <td>
            <Form.Check key={productPayment.product.id} aria-label={productPayment.product.id}
                        checked={isInPartialPaymentsList}
                        disabled={(isInsuranceActivity && !isLastInsurance) || (!isInsuranceActivity && !isLastActivity)}
                        onChange={() => {
                          if (isInPartialPaymentsList) {
                            setPartialPayments(partialPayments.filter((pp) => pp.productId !== productPayment.product.id));
                          }
                          else {
                            setPartialPayments([...partialPayments, { productId: productPayment.product.id!, partialPayment: amountToPay }])
                          }
                        }}/>
          </td>
        </tr>
      )
    });

    return (
      <tbody>{activityFeesRows}</tbody>
    )
  }

  const getPartialPriceInput = (activityFeeId: string, price: number) => {
    return (
      <div>
        <p className="mr-2 d-inline-block">$ </p>
        <div className="w-75 d-inline-block">
          <Form.Control
            id={activityFeeId}
            key={activityFeeId}
            type="number"
            step=".00"
            defaultValue={price}
            min={0}
            max={price}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handlePartialPriceChange(e) }}
          />
        </div>
      </div>
    )
  }

  const handlePartialPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const activityFeeId = e.target.id;
    let price = Number(e.target.value);
    const defaultPrice = Number(e.target.defaultValue);
    if (price > defaultPrice) {
      e.target.value = e.target.defaultValue;
      price = Number(e.target.defaultValue);
    }
    if (price < 0) {
      e.target.value = "0";
      price = 0;
    }
    const element = partialPayments.find(pp => pp.productId === activityFeeId && !pp.isRemainingPart);
    if (element) {
      const newArray = partialPayments.filter(pp => pp.productId !== element.productId);
      element.partialPayment = price;
      setPartialPayments([...newArray, element]);
    }
  }

  useEffect(() => {
    setTotal(getTotal());
  }, [activitiesFeesToPay, paymentsToMake, partialPayments])

  const getTotal = () => {
    let total = 0;
    paymentsToMake.forEach((productPayment) => {
      let newValue = productPayment.remainingPriceToPay;
      const partialPayment = partialPayments.find(pp => pp.productId === productPayment.product.id);
      if (partialPayment) {
        newValue = partialPayment.partialPayment;
      }
      else {
        const missingPayment = productPayment.remainingPriceToPay;
        if (missingPayment > 0) {
          newValue = missingPayment;
        }
      }

      total = total + Number(newValue);
    })

    return total;
  }

  const handlePayButton = () => {
    const partialPaymentsList: PartialPayment[] = [];
    paymentsToMake.forEach(productPayment => {
      if (productPayment.remainingPriceToPay > 0 && productPayment.remainingPriceToPay !== productPayment.totalPriceToPay && partialPayments.findIndex(pp => pp.productId === productPayment.product.id) < 0) {
        partialPaymentsList.push({ productId: productPayment.product.id!, partialPayment: productPayment.remainingPriceToPay, isRemainingPart: true });
      }
    });
    setPartialPayments(partialPayments.concat(partialPaymentsList));
    setShowPaymentConfirmationPopup(true);
  }

  const getActivitiesFeesToPaySection = () => {
    if (activitiesFeesToPay.length === 0) {
      return <></>
    }

    return (
      <Row className="w-75">
        <Col>
          <h3>Actividades a abonar</h3>
          {getMembershipFeesToPayTable()}
          <p className="fw-bold">TOTAL: <CurrencyData value={total} /></p>
          <Button variant="primary" onClick={handlePayButton}>Pagar</Button>
        </Col>
      </Row>
    )
  }

  return (
    <>
      <Row>
        <Col xs={4}>
          <Button onClick={() => setShowAddMemberActivityPopup(true)}>Dar de alta en actividad</Button>
        </Col>
      </Row>
      <h3>Actividades actuales</h3>
      <Row>
        {getMemberActivitiesList(true)}
      </Row>
      <h3>Actividades anteriores</h3>
      <Row>
        {getMemberActivitiesList(false)}
      </Row>
      {getActivitiesFeesToPaySection()}
      <AddMemberActivityPopup member={props.member} show={showAddMemberActivityPopup}
                    setShow={setShowAddMemberActivityPopup} toastConfiguration={props.toastConfiguration}
                    operationCompleteCallback={operationCompleteCallback}
      />
      <PaymentPopup member={props.member} productFeesToPay={activitiesFeesToPay} discriminator={ACTIVITY_FEE_DISCRIMINATOR}
                    show={showPaymentConfirmationPopup} setShow={setShowPaymentConfirmationPopup}
                    toastConfiguration={props.toastConfiguration} operationCompleteCallback={operationCompleteCallback}
                    partialPayments={partialPayments} paymentsToMake={paymentsToMake}
      />
      <ConfirmationPopup titleText={"Confirmar baja"} body={removeActivityConfirmationPopupBody} show={showRemoveActivityConfirmationPopup}
                         setShow={setShowRemoveActivityConfirmationPopup} handleSubmit={formik.handleSubmit} />
    </>
  )
}
