import {Outlet} from "react-router-dom";
import { useToastConfiguration } from "../../App";

export default function Payments() {
  const { toastConfiguration } = useToastConfiguration();

  return (
    <Outlet context={{toastConfiguration}} />
  )
}
